import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import moment from 'moment';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import DateTimePicker from '../../datePickerCalender/datePicker'
import TextEditor from "../../textEditor/textEditor";
import {schema} from '../../../../../schemas/worklogDetail'
import { useWorklogDetail } from "../../../../../utils/reactQuery/worklog";
import {updateWorklogDetail} from '../../../../../sevices/apiWorklog'
import Loader from "../../../../../components/Loader";
import './editWorklog.scss'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


function EditWorklog({ onClose, card_log_id, setShowEdit, showEdit,cardId }) {
    const queryClient = useQueryClient();
    const {
      isLoading: isWorklogLoading,
      worklogDetail,
      isError: isWorklogError,
     
    } = useWorklogDetail(card_log_id);
 
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [apiError, setApiError] = useState("");
    const [loading, setLoading] = useState(false);
    const [isdisable, setDisable] = useState(false);
    const [description,setDescription] =useState(null);
    const [date, setDate] = useState(null)
    const [hours, setHours] = useState(null)
    const [testContent, setTextContent] = useState(null)
    // let description ='';
 
    console.log("WORKLOGDETAILS", worklogDetail?.data?.data?.responseData[0])

    const { register, handleSubmit, reset, getValues, formState ,setValue} = useForm({
        defaultValues: {
            hour_spent:worklogDetail?.data?.data?.responseData[0]?.hour_spent,
            work_performed: worklogDetail?.data?.data?.responseData[0]?.work_performed,
            worklog_time:worklogDetail?.data?.data?.responseData[0]?.worklog_time,
        },
        resolver: yupResolver(schema),
      });
      const { errors } = formState;

       const { mutate: updateWorklogDetailMutate } = useMutation({
    mutationFn: updateWorklogDetail,
    onSuccess: (res) => {
      // console.log("Worklog Updated successfully");
      queryClient.invalidateQueries({
        queryKey: [`worklog`,cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`worklogDetail`, card_log_id]
      });
    
      setDisable(false)
      // console.log(res)
      setLoading(false);
      // reset();
      toast.success("successfully updated");
      // onClose();
      setShowEdit(false);
      // refetchWorklogDetail();
    },
    onError: (error) => {
      console.error("Error update Worklog", error);
      setLoading(false);
      setDisable(false)
    },
  });

  const handleClose = ()=>{
    setShowEdit(false)
  }
  // console.log(card_log_id)
  const handleUpdate = (e) => {
    setDisable(true)
    // console.log("edit",data);
    setApiError("");
    setLoading(true);
    // console.log(data);
    updateWorklogDetailMutate({
      card_log_id:card_log_id,
      data : {
        hours_spent:hours,
        work_performed:testContent,
        date_time: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
      }
     
     
    });
  };






  useEffect(()=>{
    setDescription(worklogDetail?.data?.data?.responseData[0]?.work_performed)
    setDate(worklogDetail?.data?.data?.responseData[0]?.worklog_time)
    setHours(worklogDetail?.data?.data?.responseData[0]?.hour_spent)
    // console.log("object",worklogDetail?.data?.data?.responseData[0]?.work_performed);
  },[ worklogDetail?.data?.data?.responseData[0]?.work_performed,isWorklogLoading,testContent])
  
  // const setTextContent = (description)=>{
  //   setValue('work_performed',description)
  //   console.log(formState);
  // }
  const handleHoursChange = (e)=>{
    setHours(e.target.value)
  }
  const data = {
    card_log_id:card_log_id,
    hour_spent:hours,
    work_performed:testContent,
    worklog_time: date,
  }
  // console.log(data)
  return(
    <React.Fragment>
    <BootstrapDialog
      fullWidth
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showEdit}
      fullScreen={fullScreen}
      sx={{ borderRadius: "20px", height: "fitContent"}}
      // PaperComponent={{sx:{height:"100%"}}}
      component="form"
      // onSubmit={handleSubmit(submit)}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          bgcolor: "rgba(242, 246, 254, 1)",
          // color: "#1B3E61",
          // fontWeight: "bolder",
          // fontFamily: "DM Sans",
        }}
        id="customized-dialog-title"
      > <div className="add-worlog-title-dialog">
        Edit Work Log
      </div>
        
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
      {apiError && <Alert severity="error">{apiError}</Alert>}
      {isWorklogLoading && <div className="description-loader"><Loader color={"black"}/></div> }
      {(description) && (<>
      <Typography gutterBottom>
              <div className="work-hour-box">
              <p className="work-hour-title">Work Hours</p>
            <TextField
              id="hour_spent"
              variant="outlined"
              name="hour_spent"
              className="work-hour"
              value={hours}
              // {...register("hour_spent")}
              onChange={handleHoursChange}
              
            />
            
            </div>
        </Typography>
        <Typography>
              <p className="work-discription-title">Work Description</p>
              <div className="text-editor">
           <TextEditor  setTextContent={setTextContent} description={description}/>
              </div>     
        </Typography>
        <Typography>
              <p className="work-date-title">Date</p>
              <div className="work-date-input">
              <DateTimePicker setDate={setDate} date={date}/>
              </div>     
        </Typography>
        </>)}
        
        <div className='worklog-btn'>
        <Button className='worklog-btn-save' autoFocus variant="contained" disabled={isdisable} onClick={handleUpdate}>
          <div className='btn-content'>
            Update
          </div>
        </Button>
        <Button className='worklog-btn-cancel' autoFocus variant="outlined" disabled={isdisable}  onClick={handleClose}>
          <div className='btn-content'>
          Cancel
          </div>
        </Button>
        </div>
      </DialogContent>
    </BootstrapDialog>
  </React.Fragment>
  )

}
export default EditWorklog;