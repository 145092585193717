import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useParams, useSearchParams } from "react-router-dom";

import CheckBox from "../../../../styles/svg/id-icon.svg";
import AssignCard from "./AssignCard";
import EditTitle from "./EditTitle";
import {isAdmin} from '../../../../utils/constants'
import { useSelector } from "react-redux";

function KanbanCard({ project, onDragEnd, boardName, index, id, status }) {
  const { id: boardId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  // const searchParams = new URLSearchParams(useSearchParams());
  // const setSearchParams = useSearchParams();
  const [collapsed, setCollapsed] = useState(true);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const currentUser = useSelector((state) => state.users.logInUser);
    const queryClient = useQueryClient();
    const cachedData = queryClient.getQueryData(["boardName", boardId]);
  //   const queryClient = useQueryClient();
  //   const cachedData = queryClient.getQueryData({ queryKey: ["todos"] });
  // console.log("project", project);
  const tooltipName = `${project.assigned_to_user?.first_name || ""} ${
    project.assigned_to_user?.last_name || ""
  }`;
  const firstName = project.assigned_to_user?.first_name[0];
  const lastName = project.assigned_to_user?.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  //project.assigned_to_user

  const handleDetails = (e) => {
    // e.stopPropagation();
    // setCardId(project.card_id);
    // console.log("searchParams",project?.card_id);
    // console.log("searchParams",project);
    // setShowDetails(true);
    // setSearchParams({'card_id': project?.card_id })
    updateSearchParams("card_id", project?.card_id);
  };

  // console.log("project",project)

  const accessDragCard =
    currentUser?.user_id == project?.assigned_to_user_id ||
    currentUser?.user_id == project?.reporter ||
    currentUser?.user_id == cachedData?.data?.data.responseData?.owner_user_id || isAdmin(); 

  const accessForAssignOrEditTitle =
    currentUser?.user_id == project?.reporter ||
    currentUser?.user_id ==
      cachedData?.data?.data.responseData?.owner_user_id ||
    isAdmin(); 

  const showEditButton = ()=>{
    if (accessForAssignOrEditTitle) setHoveredIndex(true);
  }

   const updateSearchParams = (key, value) => {
     const newSearchParams = new URLSearchParams(searchParams);
     newSearchParams.set(key, value);
     setSearchParams(newSearchParams);
   };
  

  return (
    <Draggable
      key={id.toString()}
      draggableId={project.card_id.toString()}
      index={index}
      isDragDisabled={!accessDragCard}
    >
      {(provided, snapshot) => (
        <>
         
          <div
            className="card-style "
            // draggable={true}
            // onDragEnd={(e) => {
            //   onDragEnd(e, project);
            // }}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onClick={handleDetails}
            //  onClick={()=>{setSearchParams('card_id', project?.card_id )
            //  handleDetails()
            // }}
            onMouseEnter={showEditButton}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div className="d-flex">
              <EditTitle project={project} hoveredIndex={hoveredIndex} />
            </div>
            {collapsed ? null : (
              <div>
                <strong>Description: </strong>
                {project.description}
                <br />
              </div>
            )}

            <div className="d-flex">

              {
                project.labels[0] != "" && (
                  <div className="d-flex">
                  {project.labels.map((el) => (
                    <div className="card-level-tag mr-1">
                      <p className="card-level-tag-text">{el}</p>
                    </div>
                  ))}
                </div>
                )
              }

           

                { project?.epic && (
                   <div className="d-flex">
                   {/* {project.labels.map((el) => ( */}
                     <div className="card-epic-tag mr-1">
                       <p className="card-epic-tag-text">{project?.epic?.epic_name}</p>
                     </div>
                   {/* ))} */}
                 </div>
                )}
           
           </div>


            <div className="card-bottom d-flex align-items-center justify-content-space-between">
              <div>
                <p className="d-flex align-items-center">
                  <img src={CheckBox} className="mr-1" />{" "}
                  <p className="card-id-number">
                    {`${boardName.substring(0, 3)}`.toUpperCase()}-
                    {project.card_id}
                  </p>
                </p>
              </div>
              <div>
                <AssignCard
                  tooltipName={tooltipName}
                  project={project}
                  name={name}
                  accessForAssign={accessForAssignOrEditTitle}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Draggable>
  );
}

export default KanbanCard;
