import React from "react";
import { Avatar, Tooltip } from "@mui/material";
import Badge from "@mui/material/Badge";

import AdminLogo from "../../styles/svg/avtar-admin-icon.svg";

export default function Default({
  title,
  username,
  user_image,
  className,
  children,
  unassigned = false,
  isAdmin = false,
  onClick,
  style = {},
}) {
  // console.log("style", style);
  const convertedImage = unassigned
    ? user_image
    : process.env.REACT_APP_BASE_URL + user_image;
  return (
    <>
      {isAdmin ? (
        <Tooltip title={`${title}`.trim()} onClick={onClick}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={<img src={AdminLogo} />}
          >
            <Avatar
              alt={username}
              src={convertedImage}
              title=""
              className={className}
              sx={style}
            >
              {`${children}`.toUpperCase()}
            </Avatar>
          </Badge>
        </Tooltip>
      ) : (
        <Tooltip title={`${title}`.trim()} onClick={onClick}>
          <Avatar
            alt={username}
            src={convertedImage}
            title=""
            className={className}
            sx={ style}
          >
            {`${children}`.toUpperCase()}
          </Avatar>
        </Tooltip>
      )}
    </>
  );
}
