import {
  AvatarGroup,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Avatar from "../../../../../components/Avatar";
import { getBoardUsers } from "../../../../../sevices/apiBoards";
import AddUsers from "./AddUsers";
import { useBoardUsers } from "../../../../../utils/reactQuery/users";
import { createAvtarName } from "../../../../../utils/helpers";
import { localStorageInstance } from "../../../../../utils/localStorage";
import RemoveUser from "./RemoveUser";
import { useDispatch } from "react-redux";
import { filterUser } from "../../../../../redux/usersSlice";
import { useSelector } from "react-redux";

function Users({ boardName, boardId, ownerId }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const filterUserId = searchParams.get("search");

  const { isLoading, error, data, isError } = useBoardUsers(id);

  useEffect(() => {
    setUsers(
      data?.data?.data?.responseData ? data?.data?.data?.responseData : []
    );
  }, [isLoading, id, data]);

  const closeUserList = () => {
    setAnchorEl(null);
  };

  const filterUserById = (userId) => {
    dispatch(filterUser(userId));
    updateSearchParams('search',userId)
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };
  const removeSearchParam = (key) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(key);
    setSearchParams(newSearchParams);
  };
  return (
    <>
      <Stack spacing={2} direction="row" className="align-items-center">
        <div className="d-flex justify-content-center align-items-center">
          {filterUserId && (
            <Button
              size="small"
              sx={{ color: "#1b3e61" }}
              onClick={() =>{ 
                removeSearchParam('search')
                dispatch(filterUser(null))}}
            >
              Clear
            </Button>
          )}
          <AvatarGroup
            total={users.length}
            // onClick={(event) => setAnchorEl(event.currentTarget)}
            renderSurplus={(surplus) => (
              <span onClick={(event) => setAnchorEl(event.currentTarget)}>
                +{users.length - 4}
              </span>
            )}
            className={`avatar-group cursor-pointer ${"avatar-group-border"}`}
          >
            {users.map((el) => (
              <Avatar
                key={`user-avtar-${el.user_id}`}
                onClick={() => filterUserById(el.user_id)}
                username={el.username}
                user_image={el.user_image}
                title={el.username}
                className={`header-avatar ${
                  el.user_id == filterUserId ? "active-avtar" : ""
                }`}
                style={{
                  bgcolor: el?.profile_color?.background,
                  color: el?.profile_color?.text,
                }}
              >
                {createAvtarName(el.username)}
              </Avatar>
            ))}
          </AvatarGroup>

          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeUserList}
            MenuListProps={{
              "aria-labelledby": "epics-board-dropdown",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 14,
                  height: 14,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            className="assign-board-owner-list"
          >
            {users
              .sort(function (a, b) {
                return a.username.localeCompare(b.username);
              })
              .map((el) => {
                return (
                  <RemoveUser
                    ownerId={ownerId}
                    user={el}
                    onClick={() => filterUserById(el.user_id)}
                    closeUserList={closeUserList}
                    isSelected={el.user_id == filterUserId}
                    removeSearchParam={removeSearchParam}
                  />
                );
              })}
          </Menu>

          <AddUsers boardName={boardName} ownerId={ownerId} />
        </div>
      </Stack>
    </>
  );
}

export default Users;
