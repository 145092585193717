import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button, Menu, Popover } from "@mui/material";
import VectorArchived from "../../../../styles/svg/archive-btn-icon.svg";
import restoreArchiveIcon from "../../../../styles/svg/restore-board.svg";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { archiveBoard } from "../../../../sevices/apiBoards";
import { useParams } from "react-router-dom";
import { objectToFormData } from "../../../../utils/helpers";
import { changeExpand } from "../../../../redux/boardListSlice";
import { useDispatch } from "react-redux";

function Archive() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["boardName", id]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [archiveDialog, setArchiveDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const isArchive = cachedData?.data?.data?.responseData?.is_archive;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setArchiveDialog(false);
  };

  const open = Boolean(anchorEl);
  const idPopover = open ? "simple-popover" : undefined;

  const { mutate: archiveMutate } = useMutation({
    mutationFn: archiveBoard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boards"],
      });
      dispatch(changeExpand(isArchive ? "panel1" : "panel2"));
      queryClient.invalidateQueries({
        queryKey: ["boardName", id],
      });
      setLoading(false);
      toast.success(res.data.message);
      handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
      handleCloseDialog();
    },
  });

  const callback = () => {
    const data = objectToFormData({ is_archive: !isArchive, board_id: id });
    setLoading(true);
    archiveMutate({ id, data });
  };
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        className="white-button archive-button"
        onClick={handleClick}
        sx={{
          height: "38px",
          "&:hover": {
            outline: "none",
            backgroundColor: "rgb(240 249 254)",
            border: "1px solid #1b3e61",
          },
        }}
      >
        <MoreHorizIcon />
      </Button>

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        className="archive-popover"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}
        PaperProps={{
          elevation: 0,
          sx: {
            paddingTop: 0,
            paddingBottom: 0,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <Button
          sx={{ textTransform: "capitalize", color: "rgba(33, 43, 54, 1)" }}
          onClick={() => setArchiveDialog(true)}
        >
          <img src={isArchive ? restoreArchiveIcon : VectorArchived} alt="Not Found" className="mr-8" />
          {isArchive ? "Restore Board" : "Archive the board"}
        </Button>
      </Menu>

      {archiveDialog && (
        <ConfirmationDialog
          open={archiveDialog}
          handleClose={handleCloseDialog}
          message={
            isArchive
              ? "Are you sure you want to restore the board ?"
              : "Are you sure you want to archive this board? Archiving the board will hide it from view and move it to the archive section. You can always restore it later if needed. Please confirm your action."
          }
          callback={callback}
          loading={loading}
          btnTitle={isArchive ? "Restore" : "Archive"}
          title={isArchive ? "Restore Board" : "Archive Board"}
        />
      )}
    </>
  );
}

export default Archive;
