import { Button } from '@mui/material';
import React from 'react'
import Download from './Download';
import { useSelector } from 'react-redux';

function Header() {
  const totalHours = useSelector((state) => state.search.totalWorkHours);
  return (
    <div className="d-flex justify-content-space-between">
      <p className="heading-worklog-tab">Worklogs</p>
      <div className="d-flex">
        <div className="mr-4">
          <p className="total-work">Total Work</p>
          <span className="hours-header">{totalHours} hours</span>
        </div>
        <Download />
      </div>
    </div>
  );
}

export default Header