import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchEpic: [],
};

const search = createSlice({
  name: "epic",
  initialState,
  reducers: {
    searchCard: {
      prepare(searchEpic) {
        return {
          payload: { searchEpic },
        };
      },
      reducer(state, action) {
        state.searchEpic = Array.isArray(action.payload.searchEpic) 
          ? action.payload.searchEpic 
          : [action.payload.searchEpic];
      },
    },
  },
});

export const { searchCard } = search.actions;

export default search.reducer;