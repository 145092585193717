import { useQuery } from "@tanstack/react-query";
import { getCards,getCard, getCardAttachment } from "../../sevices/apiCards";
import {getComments} from "../../sevices/apiComment";
export function useCards(id) {
  const {
    isLoading,
    data: cards,
    error,
    isError,
  } = useQuery({
    queryKey: [`cards`, id],
    queryFn: () => getCards(id),
  });

  return { isLoading, error, cards, isError };
}

export function useComment(id) {
  const {
    isLoading,
    data: comment,
    error,
    isError,
  } = useQuery({
    queryKey: [`comment`,id],
    queryFn: () => getComments(id),
  });

  return { isLoading, error, comment, isError };
}

export function useCard(id) {
  const {
    isLoading,
    data: card,
    error,
    isError,
  } = useQuery({
    queryKey: [`card`, id],
    queryFn: () => getCard(id),
  });

  return { isLoading, error, card, isError };
}

export function useCardAttachment(id) {
  const {
    isLoading,
    data: cardAttachment,
    error,
    isError,
  } = useQuery({
    queryKey: [`cardAttachment`, id],
    queryFn: () => getCardAttachment(id),
  });

  return { isLoading, error, cardAttachment, isError };
}
