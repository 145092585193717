import React, { useEffect, useRef, useState } from 'react';
import Quill from 'quill';
import './description.scss'
const ReadOnlyDescription = ({description}) => {
  useEffect(() => {
    const options = {
      readOnly: true,
      modules: {
        toolbar: null
      },
      theme: 'snow',
      placeholder: description ? "" : 'Add Description...',
    };
    const quill = new Quill('#editor1', options);
    const Delta = Quill.import('delta');
    if (description) {
        quill.root.innerHTML = description;
    }
//     quill.root.innerHTML =  description || (<span className="Add-desc-message2">
//     Add description here....
//   </span>)
  }, [description]); // Empty dependency array means this effect runs only once after the initial render

  return (
    <div id="editor1" className="description-editor"></div>
  );
}

export default ReadOnlyDescription;
