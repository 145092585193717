import React from 'react'
import {
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextareaAutosize,
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import CheckBox from "../../../../styles/svg/id-icon.svg";
import createEpicimg from "../../../../../styles/svg/createEpic.svg"

function CreatEpicField({
  handleKeyDown,
  setTitle,
  setCreateBtnOpen,
  edit = false,
  value,
  className = "create-epic-textarea",
}) {
  return (
    <FormControl fullWidth onClick={(e) => e.stopPropagation()}>
      <TextareaAutosize
        aria-label="empty textarea"
        placeholder="What needs to be done?"
        minRows={1} // Set the minimum number of rows
    
        className={className}
        defaultValue={value}
        onKeyDown={handleKeyDown}
        onChange={(e) => setTitle(e.target.value)}
        onBlur={setCreateBtnOpen}
        autoFocus
        
      />
      {!edit && (
        <InputAdornment position="end">
          {/* <IconButt edge="end" aria-label="send"> */}
          {/* <Send sx={{ marginBottom: "35px" }} />{" "} */}
          <img src={createEpicimg} className="create-epic-field-image"/>
          {/* <ExpandMoreIcon className="mb-6" /> */}
          {/* Replace Send with your desired icon */}
          {/* </IconButt> */}
        </InputAdornment>
      )}
    </FormControl>
  );
}

export default CreatEpicField