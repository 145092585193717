import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  Typography,
} from "@mui/material";
import React from "react";
import SidebarListItem from "./SidebarListItem";

function AccordionNav({
  heading,
  icon,
  listing,
  defaultExpanded,
  uniqueKey,
  expanded,
  onChange,
}) {
  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      sx={{ boxShadow: "none" }}
      defaultExpanded={defaultExpanded}
      className="menu-Accordion"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className="menu-AccordionSummary"
      >
        <img src={icon} alt="Not Found" />
        {<span className="navigation-heading">{heading}</span>}
      </AccordionSummary>
      <AccordionDetails className="menu-AccordionDetails" key={uniqueKey}>
        {listing && listing.length ? (
          <List
            dense
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {listing.map((board) => (
              <SidebarListItem key={board.board_id} board={board} />
            ))}
          </List>
        ) : (
          <Typography
            variant="caption"
            gutterBottom
            className="text-center color-white d-flex justify-content-center pl-0"
            sx={{ marginLeft: "-16px" }}
          >
            Boards Not Found
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionNav;
