import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

import { useBoardUsers } from "../../../../utils/reactQuery/users";
import Avatar from "../../../../components/Avatar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAvtarName } from "../../../../utils/helpers";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { updateCard } from "../../../../sevices/apiCards";
import NotAssignCardIcon from "../../../../styles/svg/unassigned.svg";

function AssignCard({ tooltipName, name, project, accessForAssign }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const { isLoading, error, data, isError } = useBoardUsers(id);

 

  useEffect(() => {
    const sortedUsers = data?.data?.data?.responseData
      ? data?.data?.data?.responseData.sort(function (a, b) {
          return a.username.localeCompare(b.username);
        })
      : [];
    const index = sortedUsers.filter(
      (user) => user.user_id == project?.assigned_to_user_id
    );
    let user = sortedUsers;
    if (index.length) {
      user = sortedUsers.filter(
        (user) => user.user_id !== project?.assigned_to_user_id
      );
      user.unshift(index[0]);
    }
    setUsers(user);
  }, [isLoading, id, data, project]);

  const handleClick = (event) => {
    // console.log("EVENT>>>>>>>>",event)
    event.stopPropagation();
    if (accessForAssign) setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const { mutate: assignCardMutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      // console.log(project);
      queryClient.invalidateQueries({
        queryKey: [`card`],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
   
      setLoading(false);
      // console.log(res);
      //    reset();
      toast.success(res.data.message);
      setOpenDialog(false);
     
    },
    onError: (error) => {
      console.error("Error assig board", error);
      toast.error(error.response.data.message);
      setLoading(false);
      setOpenDialog(false);
    },
  });

  const assignUser = (id) => {
    setOpenDialog(true);
    setUserId(id);
  };
  const callback = (event) => {
    event.stopPropagation();
    setLoading(true);
    assignCardMutate({
      card_id: project.card_id,
      data: { assigned_to_user_id: userId.user_id },
    });
  };

  const closeConfirmation = (event) => {
    event.stopPropagation();
    setOpenDialog(false);
  };

  return (
    <>
      {openDialog && (
        <ConfirmationDialog
          open={openDialog}
          handleClose={closeConfirmation}
          message={`Are you sure to assign this card to ${userId.username}?`}
          callback={callback}
          loading={loading}
          btnTitle={"Assign"}
          title={"Assign Card"}
        />
      )}
      <div onClick={handleClick}>
        {project?.assigned_to_user_id ? (
          <Avatar
            title={tooltipName}
            className={"card-avtar header-avatar"}
            user_image={project?.assigned_to_user?.user_image}
            style={{
              bgcolor: project?.assigned_to_user?.profile_color?.background,
              color: project?.assigned_to_user?.profile_color?.text,
            }}
          >
            {name}
          </Avatar>
        ) : (
          <Avatar
            unassigned={true}
            title={"Unassigned"}
            className={"card-avtar header-avatar"}
            user_image={NotAssignCardIcon}
          />
        )}
      </div>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="assign-board-owner-list"
      >
        {users.map((el) => {
          const convertedImage = process.env.REACT_APP_BASE_URL + el.user_image;
          return (
            <MenuItem
              key={`user-avtar-dropdown-card-assign-${el.user_id}`}
              onClick={() => assignUser(el)}
              className={
                project?.assigned_to_user_id &&
                project.assigned_to_user_id == el.user_id
                  ? "active-assign"
                  : ""
              }
            >
              {/* {convertedImage && el.user_image ? (
                <img
                  src={convertedImage}
                  alt="not found"
                  className="user-avtar-dropdown mr-8"
                />
              ) : ( */}
                <Avatar
                  key={`user-avtar-${el.user_id}`}
                  username={el.username}
                  user_image={el.user_image}
                  title={el.username}
                  className="user-avtar-dropdown user-avatar mr-8"
                  style={{
                    bgcolor: el?.profile_color?.background,
                    color: el?.profile_color?.text,
                  }}
                >
                  {createAvtarName(el.username)}
                </Avatar>
       
              {el.username}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default AssignCard;
