import * as yup from "yup";

export const schema = yup
  .object({
    board_name: yup.string().required("Board name is required"),
    workflow_id: yup
      .number()
      .positive()
      .integer()
      .required("Board Workflow is required"),
    owner_user_id: yup.number().required("Board owner is required"),
  })
  .required();
