import { localStorageInstance } from "./localStorage";

export function isAdmin() {
  const userData = localStorageInstance.getItem("role");
  return userData == "Super Admin";
}

export function currentUserId() {
  const userData = localStorageInstance.getItem("userId");
  return userData;
}
