import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import './datePickerCalender.scss'
const ProSpan = styled('span')({
  display: 'inline-block',
  height: '1em',
  width: '1em',
  verticalAlign: 'middle',
  marginLeft: '0.3em',
  marginBottom: '0.08em',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundImage: 'url(https://mui.com/static/x/pro.svg)',
});

function Label({ componentName, valueType, isProOnly }) {
  const content = (
    <span>
      {/* <strong>{componentName}</strong> for {valueType} editing */}
    </span>
  );

  if (isProOnly) {
    return (
      <Stack direction="row" spacing={0.5} component="span">
        <Tooltip title="Included on Pro package">
          <a
            href="https://mui.com/x/introduction/licensing/#pro-plan"
            aria-label="Included on Pro package"
          >
            <ProSpan />
          </a>
        </Tooltip>
        {/* {content} */}
      </Stack>
    );
  }

  return content;
}




export default function CommonlyUsedComponents({setDate, date,register}) {
  // const [dateTime, setDateTime] = useState('');
  // const currentDate = moment()
  // const newDate = date ? date : moment(currentDate).format('YYYY-MM-DDTHH:mm:ss')
 
  // console.log("momentTime",new moment(date).local().format('DD-MM-YYYYTHH:mm:ss'));
  // const  localDate = (date)=>{
  //   const newDate = moment.utc(date);
  //   const localDate = newDate.local().toDate();
  //   return localDate
    
  // }
  
  // console.log("INPUTlocal1",newDate)
  console.log("default date",date);
  
  const newDate = moment.utc(date);
  const localDate = newDate.local().toDate();

  // console.log("momentTime",localDate)
  // console.log("INPUTlocal2",localDate)
  // const newDate = moment.utc(date);
  // const localDate = newDate.local().toDate();
  // console.log("momentTime",);

  const handleDateChange = (date)=>{
    console.log("handleChange//",date)
    const utcStart = new moment(date).utc();
    const selectedDate = date ? utcStart.format() : moment();
    console.log("handleChange",selectedDate)
    // setDateTime(selectedDate)
    setDate(utcStart)
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className='datepicker'>
      <DemoContainer
        className="datepicker"
        components={[
          'DatePicker',
        ]}
      >
        <DemoItem label={<Label componentName="DateTimePicker" valueType="date time" />}>
          
          <DateTimePicker defaultValue={date ? moment(localDate) : moment().local()}  onChange={handleDateChange}/>
        </DemoItem>
      </DemoContainer>
      </div>
    </LocalizationProvider>
  );
}


// import * as React from 'react';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import moment from 'moment';
// export default function CommonlyUsedComponents({setDate, date}) {
//     const handleDateChange = (date)=>{
//     const selectedDate = date ? date : null;
//     setDate(selectedDate)
//   }
//   const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
//   const formattedDate = date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '';
//   return (
//     <LocalizationProvider dateAdapter={AdapterMoment}>
//       <DemoContainer components={['DateTimePicker']}>
//         <DateTimePicker defaultValue={date ? formattedDate : currentDate} onChange={handleDateChange}/>
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }
