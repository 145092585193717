import { useQuery } from "@tanstack/react-query";
import { getWorkflowById, getWorkflows } from "../../sevices/apiWorkflows";

export function useWorkflow() {
  const {
    isLoading,
    data: workflows,
    error,
    isError,
  } = useQuery({
    queryKey: ["workflows"],
    queryFn: getWorkflows,
  });

  return { isLoading, error, workflows, isError };
}

export function useWorkflowById(id, board_id) {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: [`workflow`, board_id],
    queryFn: () => getWorkflowById(id, board_id),
  });

  return { isLoading, error, data, isError };
}
