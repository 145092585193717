import React, { useState } from 'react'
import { DateRangePicker } from "react-date-range";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch } from 'react-redux';
import { setDateRange } from '../../../../redux/SearchSlice';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

function DateRange({ onClose }) {
  const dispatch = useDispatch();
  const dateRange = useSelector((state) => state.search.dateRange);
  const [state, setState] = useState([
    dateRange
      ? dateRange
      : {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
  ]);

  const handleChange = (item) => {
    setState([item.selection]);
    // dispatch(setDateRange(item.selection));
  };
  return (
    <div className="d-grid">
      <DateRangePicker
        onChange={handleChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={state}
        direction="horizontal"
      />
      <div className="d-flex">
        <Button
          onClick={() => {
            dispatch(setDateRange(null));
            onClose();
          }}
          className="reset-date-picker"
        >
          Reset
        </Button>
        <Button
          onClick={() => {
            dispatch(setDateRange(state[0]));
            onClose();
          }}
          className="submit-date-picker"
          variant='contained'
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default DateRange