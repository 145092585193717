import { useQuery } from "@tanstack/react-query";
import { getHistory } from "../../sevices/apiHistory";
import { getWorklog, getWorklogDetail } from "../../sevices/apiWorklog";

export function useWorklog(id) {
    const {
      isLoading,
      data: worklog,
      error,
      isError,
    } = useQuery({
        queryKey: [`worklog`,id],
      queryFn: () => getWorklog(id),
    });
  
    return { isLoading, error, worklog, isError };
  }

  export function useHistory(id) {
    const {
      isLoading,
      data: history,
      error,
      isError,
    } = useQuery({
        queryKey: [`history`,id],
      queryFn: () => getHistory(id),
    });
  
    return { isLoading, error, history, isError };
  }
  

  export function useWorklogDetail(id){
    const {
      isLoading,
      data: worklogDetail,
      error,
      isError,
    } = useQuery(
      {
        queryKey: [`worklogDetail`, id],
        queryFn: ()=> getWorklogDetail(id)
      }
    );
    return {isLoading, error, worklogDetail, isError}
  }