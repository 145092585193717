import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Auth } from "./Auth/Auth";
import { Pages } from "./Pages/Pages";
// import { localStorageInstance } from "../utils/localStorage";
// import { useLoginUser } from "../utils/reactQuery/users";
// import { useDispatch } from "react-redux";
// import { user } from "../redux/usersSlice";

const router = createBrowserRouter([Auth, Pages]);

function Router() {
  //   const dispatch = useDispatch();
  // const id = localStorageInstance.getItem("userId")
  //   ? localStorageInstance.getItem("userId")
  //   : localStorageInstance.getItem("userData")?.user_id;
  // const { data } = useLoginUser(id);
  // // console.log("me", data);
  // if (!data && id) return <></>; 
  // if(data && id){
  //   dispatch(user(data.data.data.responseData));
  // }
  
  return <RouterProvider router={router} />;
}

export default Router;
