import React from 'react'
import { useState,} from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "../../../../../../components/Avatar"
import Typography from "@mui/material/Typography";
import { Button } from "@mui/base";
import Loader from "../../../../../../components/Loader";
import EditTextFields from "../editComment/editComment";
import ConfirmationDialog from "../../../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { deleteComment } from "../../../../../../sevices/apiComment";
import {localStorageInstance} from "../../../../../../utils/localStorage"
import CustomDateFormatter from '../../../dateTimeFormateConverter/dateTimeFormateConverter';
import { useParams } from 'react-router-dom';
import { useBoardName } from '../../../../../../utils/reactQuery/boards';
import { isAdmin } from "../../../../../../utils/constants";
import {currentUserId} from "../../../../../../utils/constants";
import { useCard } from '../../../../../../utils/reactQuery/cards';
import { useSelector } from 'react-redux';
import GetHeadShotName from '../../../../../../utils/getHeadShotName';
function ListComment({data, cardId}) {
    const queryClient = useQueryClient();
    const { id } = useParams()
    const { boardName } = useBoardName(id);
    const boardOwnerId = boardName?.data?.data?.responseData?.owner_user_id
    const {
      isLoading: isCardLoading,
      card,
      isError: isCardError,
    } = useCard(cardId);
    const cardData = card?.data?.data?.responseData
    const [loading, setLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    // const [messg, setMessg] = useState(null);
    console.log("LIST////",data)
    const user = useSelector((state) => state.users.logInUser);
    const firstName = user.first_name[0];
    const lastName = user.last_name[0];
    const name = `${firstName || ""}${lastName || ""}`;
    const tooltipName = `${user.first_name || ""} ${
      user.last_name || ""
    }`;

    const headShotName = GetHeadShotName(data?.username)

    const { mutate: deleteCommentMutate } = useMutation({
        mutationFn: deleteComment,
        onSuccess: (res) => {
          queryClient.invalidateQueries({
            queryKey: [`comment`, cardId],
          });
          queryClient.invalidateQueries({
            queryKey: [`history`, cardId],
          });
          setLoading(false);
          // console.log(res);
          //    reset();
          toast.success(res.data.message);
          handleClose();
        },
        onError: (error) => {
          console.error("Error Delete Attachments", error);
          toast.error(error.response.data.message);
          setLoading(false);
          handleClose();
        },
      });
    
      const handleEdit = ()=>{
        setShowEdit(true)
      }
    
      const handleDelete = (comment_id) => {   
        setShowDelete(true);
      };
    
      const handleClose = () => {
        setShowDelete(false);
      };
    
      function callback() {
        setLoading(true);
        deleteCommentMutate(data.comment_id);
      }
  return (
    <>
     {showDelete && (
        <ConfirmationDialog
          open={showDelete}
          handleClose={handleClose}
          message={
            "Are you sure to you want to delete this item? This action can not be undone"
          }
          callback={callback}
          loading={loading}
          btnTitle={"Delete"}
          title={"Delete Conformation"}
        />
      )}  
    <Box sx={{ flexGrow: 1, px: 1, width: "400px" }}>
          <Grid container spacing={2}>
            <div className="comment-box">
              <Grid>
              <Avatar
            title={data.username}
            className={" header-avatar add-comment-avtar comment-box-img"}
            user_image={data.user_image}
            style={{
              bgcolor: data?.user_profile_color?.background,
              color: data?.user_profile_color?.text,
            }}
          >
            {headShotName}
          </Avatar>
                {/* <Avatar title={tooltipName} className="comment-box-img" user_image={data.user_image}>  {name}</Avatar> */}
              </Grid>
              <div className="comment-box-body">
                <div className="d-flex">
                  <Grid>
                    <Typography className="comment-box-name">
                      {data.username}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography className="comment-box-date">
                      {<CustomDateFormatter date={data.comment_time}/>}
                      {/* {data.comment_time} */}
                    </Typography>
                  </Grid>
                </div>
                <Grid>
                    {showEdit ? <EditTextFields cardId={cardId}  setShowEdit={setShowEdit} comment_id={data.comment_id} messg={data.comment}/>:(<Typography className="comment-box-message">
                    {data.comment}
                  </Typography>)}
                  
                </Grid>
                {  data?.author_id == currentUserId() && (
                  <div>
                  <Button
                    className="comment-box-btn1"
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => handleDelete()}
                    className="comment-box-btn2"
                  >
                    Delete
                  </Button>
                </div>
                ) 
                }
                
              </div>
            </div>
          </Grid>
        </Box></>
  )
}

export default ListComment