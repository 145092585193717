import { Navigate, Outlet, useLocation } from "react-router-dom";
import { localStorageInstance } from "../../utils/localStorage";

const Private = () => {
  const location = useLocation();
  const currentUrl = location.pathname;

  const auth =
    localStorageInstance.getItem("token") &&
    localStorageInstance.getItem("userId");

  localStorageInstance.setItem({ key: "redirectLocation", value: currentUrl });
  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default Private;
