import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterByUserId: null,
  logInUser:{}
};

const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    filterUser: {
      prepare(filterByUserId) {
        return {
          payload: { filterByUserId },
        };
      },
      reducer(state, action) {
        state.filterByUserId = action.payload.filterByUserId;
      },
    },
    user: {
      prepare(user) {
        return {
          payload: { user },
        };
      },
      reducer(state, action) {
        state.logInUser = action.payload.user;
      },
    },
  },
});

export const { filterUser,user } = users.actions;

export default users.reducer;
