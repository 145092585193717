import { Navigate, Outlet } from "react-router-dom";
import { localStorageInstance } from "../../utils/localStorage";

const Protected = () => {
  const auth =
    localStorageInstance.getItem("token") &&
    localStorageInstance.getItem("userId");
  // const isAdmin = localStorage.getItem("isAdmin") === "Admin";
  return auth ? <Navigate to="/board" /> : <Outlet />;
};

export default Protected;
