import React from 'react'
import Boards from '../../components/Filters/Boards';
import SelectDatePicker from '../../components/Filters/SelectDatePicker';
import Epics from '../../components/Filters/Epics';
import { useSelector } from 'react-redux';
import FilterUser from './FilterUsers';

function Filters() {
  const checkedList = useSelector((state) => state.search.boardSearch);
  const myTaskUser = useSelector((state) => state.search.myTaskUser);
  return (
    <div className="worklog-filter d-flex justify-content-space-between align-items-center">
      <p className="filter-by">Filter By:</p>
      <div className='d-flex'>
        <Boards />
        <FilterUser user={myTaskUser}/>
        {checkedList && checkedList.length == 1 && <Epics />}
        {/* <Epics/> */}
        <SelectDatePicker />
      </div>
    </div>
  );
}

export default Filters