import React, { useState } from "react";
import { Button, Grid, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";


import Users from "./Users/Users";
import attachFileIcon from "../../../../styles/svg/attachment.svg";
import apicIcon from "../../../../styles/svg/epic-icon.svg";
import AttachmentBar from "../attachments";
import Archive from "./Archive";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { localStorageInstance } from "../../../../utils/localStorage";
import BoardName from "./BoardName";
import { isAdmin } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import EpicButton from './epicButton/EpicButton'
function BoradHeader({ boardName, boardId, ownerId }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["boardName", id]);
  const userData = useSelector((state) => state.users.logInUser);  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [attachmentDialog, setAttachmentDialog] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setAttachmentDialog({ ...attachmentDialog, [anchor]: open });
  };

  // console.log("header", cachedData, userData);
  

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className="remove-container-width fixed-ui"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={6} md={5} className="d-flex align-items-center">
          {boardName && <BoardName boardName={boardName} />}
        </Grid>
        <Grid item xs={6} md={7}>
          <div className="d-flex justify-content-end">
            <Stack spacing={2} direction="row">
              <Users
                boardName={boardName}
                boardId={boardId}
                ownerId={ownerId}
              />
              <EpicButton/>
              {/* <Button
                variant="outlined"
                color="primary"
                id="epics-board-dropdown"
                aria-controls={open ? "user-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(event) => setAnchorEl(event.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}
                className="white-button epics-btn"
                sx={{
                  height: "38px",
                  "&:hover": {
                    outline: "none",
                    backgroundColor: "rgb(240 249 254)",
                    border: "1px solid #1b3e61",
                  },
                }}
              >
                <img
                  src={apicIcon}
                  className="header-filter-icon"
                  alt="not found"
                />{" "}
                &nbsp; <p className="header-action-button-text">All Epics</p>
              </Button>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "epics-board-dropdown",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    width: "222px",
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left:14,
                      // right:0 ,
                      width: 14,
                      height: 14,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                className=""
              >
                <MenuItem onClick={() => setAnchorEl(null)}>Epics 1</MenuItem>
                <MenuItem onClick={() => setAnchorEl(null)}>Epics 2</MenuItem>
              </Menu> */}
              <Button
                variant="outlined"
                color="primary"
                className="white-button attach-btn"
                onClick={() =>
                  setAttachmentDialog({ ...attachmentDialog, right: true })
                }
                sx={{
                  height: "38px",
                  "&:hover": {
                    outline: "none",
                    backgroundColor: "rgb(240 249 254)",
                    border: "1px solid #1b3e61",
                  },
                }}
              >
                <img
                  src={attachFileIcon}
                  className="header-filter-icon"
                  alt="not found"
                />{" "}
                &nbsp; <p className="header-action-button-text">Attach</p>
              </Button>
              {cachedData?.data?.data?.responseData?.is_owner || isAdmin() && <Archive />}
            </Stack>
          </div>
        </Grid>
      </Grid>
      {attachmentDialog && (
        <AttachmentBar state={attachmentDialog} toggleDrawer={toggleDrawer} />
      )}
    </>
  );
}

export default BoradHeader;
