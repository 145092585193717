import React from 'react'
// import { objectToFormData } from "../../../../utils/helpers";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCardAttachment } from '../../../../../sevices/apiCards';
import { objectToFormData } from '../../../../../utils/helpers';
import attachFileIcon from "../../../../../styles/svg/attach-file.svg";
import addfile from "../../../../../styles/svg/add-file.svg";
import Button from "@mui/material/Button";
import './AttachFile.scss'
const AttachFile = ({ cardId , isIconBtn=false}) => {
    const [loading, setLoading] = React.useState(false);
    const queryClient = useQueryClient();
    // const { id } = useParams();
    const { mutate: createCardAttachmentMutate } = useMutation({
        mutationFn: createCardAttachment,
        onSuccess: (res) => {
          queryClient.invalidateQueries({
            queryKey: [`cardAttachment`, cardId],
          });
          setLoading(false);
        //   setAddAttachment(false)
        //   reset();
          toast.success("Attachment added successfully");
          //    onClose();
        },
        onError: (error) => {
          console.error("Error Add Attachments", error);
          toast.error(error.response.data.message);
          setLoading(false);
        },
      });

   

      const handleFile=(e) => {
        const files = objectToFormData({
          card_id: cardId,
          // user_id: card?.data?.data?.responseData?.assigned_to_user_id,
          file: e.target.files[0],
        });
        setLoading(true);
        createCardAttachmentMutate(files)
        // setAddAttachment(false)
        // console.log("UPLOADFILE",e.target.files[0]);
      }

      return(
        <>
        {
            isIconBtn ? 
            <div >
                <Button  component="label" className='new-add-file'>
                     <img src={addfile} alt="" />
                     <input type="file" hidden onChange={handleFile}/></Button>
                   </div> 
                   :
      
        <Button
                  variant="outlined"
                  color="primary"
                  className="white-button d-flex justify-content-center align-items-center header-attach-btn"
                  component="label"
                >
                  <img
                    src={attachFileIcon}
                    className="header-filter-icon"
                    alt="not found"
                  />{" "}
                  &nbsp; <span className="attach-btn-content" >Attach</span>
                  <input type="file" hidden onChange={handleFile}/>
                </Button>  }</>
      )
  
}

export default AttachFile