import React,{useState} from 'react'
import { useParams } from 'react-router-dom'
import { useEpic } from '../../../../../../../utils/reactQuery/epics';
import SingleSelectDropdown from './singleSelectDropdown/singleSelectDropdown';
const Epic = ({cardId,handleEpicBlur}) => {
    // const [epic, setEpic] = useState(null)
    const {id} = useParams();
    const {
        isLoading,
        epics,
        isError
      } = useEpic(id)

    const handleEpic = (epic_id)=>{
        handleEpicBlur(epic_id)
    }
const names  = epics?.data?.data?.responseData
console.log("INEPIC",names)
  return (
    <>
        <SingleSelectDropdown epicsName={names} cardId={cardId} handleEpicBlur={handleEpicBlur}/>
    </>
  )
}

export default Epic