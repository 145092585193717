import { ListItem, Tooltip, Typography } from "@mui/material";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import TaskIcon from '../../styles/svg/my-task.svg'
import './myTask.scss';
import { useDispatch } from "react-redux";
import { setTaskTab } from "../../redux/SearchSlice";

function MyTask() {
  const dispatch = useDispatch()
  return (
    <ListItem
      className="menu-ListItem my-task-page"
      onClick={()=> dispatch(setTaskTab(true))}
    >
      <NavLink to={`/my-task`} className="d-flex">
        <img
          src={TaskIcon}
          alt="not found"
          //   className="sidebar-board-owner"
        />
        <p className="my-task-name-text">Tasks</p>
      </NavLink>
    </ListItem>
  );
}

export default MyTask;
