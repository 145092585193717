import moment from "moment";
import { objectToFormData } from "../utils/helpers";
import Api from "./axios";

let controller;

export const getCards = async (board_id) => {
  return await Api.get(`/cards/${board_id}`);
};


export const createCard = async (data) => {
  return await Api.post(`/card`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const updateCard = async ({ card_id, data }) => {
  return await Api.put(`/card/${card_id}`, objectToFormData(data));
};

export const createCardAttachment = async (data)=>{
  return await Api.post(`/card_attachment`,data)
}

export const getCard = async (card_id)=>{
  return await Api.get(`/card/${card_id}`)
}

export const getCardAttachment = async (card_id)=>{
  return await Api.get(`/card_attachment/${card_id}/card_id`)
}

export const downloadCardAttachment = async (attachmenId)=>{
  return await Api.get(`/download/card-attachment/${attachmenId}`)
}

export const deleteCardAttachment = async (attachment_id)=>{
  return await Api.delete(`/card_attachment/${attachment_id}`)
}


export const getMyCards = async (user, checkedList, dateRange,epics) => {
   if (controller) {
     controller.abort();
   }
   controller = new AbortController();
   const signal = controller.signal;
  const prepareBoardQuery = `${
    checkedList.length
      ? checkedList.length > 1
        ? checkedList
            .map((el, idx) => {
              // if (idx) {
                return `&boards=${el}`;
              // } else {
              //   return el;
              // }
            })
            .join("")
        : `&boards=${checkedList[0]}`
      : ""
  }`;
  console.log(dateRange);
  const prepareDateRangeQuery =
    dateRange &&
    `&start_date=${moment(dateRange.startDate).format(
      "YYYY-MM-DD"
    )}&end_date=${moment(dateRange.endDate).format("YYYY-MM-DD")}`;
  const prepareEpic = `${
    epics.length
      ? epics.length > 1
        ? epics
            .map((el, idx) => {
              // if (idx) {
              return `&epics=${el}`;
              // } else {
              //   return el;
              // }
            })
            .join("")
        : `&epics=${epics[0]}`
      : ""
  }`;
   const prepareUser = `${
     user.length
       ? user.length > 1
         ? user
             .map((el, idx) => {
               // if (idx) {
               return `&user_id=${el.user_id}`;
               // } else {
               //   return el;
               // }
             })
             .join("")
         : `&user_id=${user[0].user_id}`
       : ""
   }`;
    if (dateRange){
       return await Api.get(
         `/cards/user/my_task?${prepareUser}${prepareBoardQuery}${prepareDateRangeQuery}${prepareEpic}`,
         {
           signal: signal,
         }
       );
    }else{

      return await Api.get(
        `/cards/user/my_task?${prepareUser}${prepareBoardQuery}${prepareEpic}`,
        {
          signal: signal,
        }
      );
      }
};