import { Button } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setTaskTab } from '../../../../redux/SearchSlice'

function Worklog() {
  const dispatch= useDispatch()
  return (
    <Button variant='contained' className='see-worklogs' onClick={()=>dispatch(setTaskTab(false))}>See Worklogs</Button>
  )
}

export default Worklog