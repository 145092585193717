import Api from "./axios"
import { objectToFormData } from "../utils/helpers";

export const getEpic = async(board_id)=>{
    return await Api.get(`/epics/${board_id}`)
}

export const createEpic = async({board_id,data})=>{
    console.log("epic",data)
    return await Api.post(`/epic/${board_id}`,data,{
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
}

export const getUserBoardEpic = async (user_id) => {
  return Api.get(`/epics/${user_id}`);
};
