import React, { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import EditIcon from '../../../../styles/svg/edit-title.svg'
import FieldCreateEditCard from './FieldCreateEditCard';
import { updateCard } from '../../../../sevices/apiCards';

function EditTitle({ project, hoveredIndex, callback }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(project.title);

  const closeEditing = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setIsEditing(false);
      updateCardAPICall();
    }
  };
  const onBlur = () => {
    setIsEditing(false);
    updateCardAPICall();
  };
  const openEditField = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const { mutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      // console.log(res);
      //    reset();
      // toast.success(res.data.message);
      // setOpenDialog(false);
      callback?.(value);
    },
    onError: (error) => {
      console.error("Error assig board", error);
      toast.error(error.response.data.message);
      // setLoading(false);
      // setOpenDialog(false);
    },
  });

  const updateCardAPICall = () => {
    if (value != project.title) {
      mutate({
        card_id: project.card_id,
        data: { title: value },
      });
    }
  };

  return (
    <>
      {isEditing ? (
        <FieldCreateEditCard
          value={value}
          setCreateBtnOpen={onBlur}
          handleKeyDown={closeEditing}
          edit={true}
          setTitle={(val) => setValue(val)}
          minRows={2}
          className={"edit-card-textarea"}
        />
      ) : (
        <>
          <p className="card-title-text">{project.title}</p>
          {hoveredIndex && (
            <div onClick={openEditField} className="ml-1 cursor-pointer">
              <img src={EditIcon} />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default EditTitle