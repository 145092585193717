import * as React from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useLebels } from '../../../../../../utils/reactQuery/boards';
import { useParams } from 'react-router-dom';

export default function ComboBox({labels,setLebelCallback,onKeyDown,onBlur}) {
  const {id} = useParams()
  const [newLabel,setNewLabel] = React.useState('');
  const { isLoading, error, lebels, isError } = useLebels(id);
  const options = lebels?.data?.data?.responseData || [];

  // console.log("lebel",labels);
  // console.log("newLabel",newLabel)

  const handleInputChange = (event, newInputValue)=>{
    // console.log("handleInputChange",newInputValue);
    setNewLabel(newInputValue)
  }
  const enterCallback = (e)=>{
    onKeyDown(e,newLabel)
  }
  
  const handleBlur = (e)=>{
    onBlur(e,newLabel)
  }
 

  return (
    
      <Autocomplete
        onBlur={handleBlur}
        autoFocus
        id="size-small-outlined-multi"
        size="small"
        options={options}
        defaultValue={labels}
        value={labels}
        onChange={setLebelCallback}
        onInputChange={handleInputChange}
        onKeyDown={enterCallback}
        sx={{
            width: '180px', // Set width to 200px
            height: '32px', // Set height to 40px
            marginRight: '10px' // Set margin-right to 10px
          }}
        // getOptionLabel={(option) => option.title}
        // defaultValue={}
        renderInput={(params) => (
          <TextField {...params}   inputProps={{
            ...params.inputProps,
            autoFocus: true, // This sets the autoFocus on the input element
          }}/>
        )}
     
      />
  
     
    
  );
}


