import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { login } from "../../sevices/apiAuth";
import GraphicElements from "../../styles/svg/graphic_elements.svg";
import Logo from "../../styles/svg/sofmenLogo.svg";
import { localStorageInstance } from "../../utils/localStorage";
import { useDispatch } from "react-redux";
import { user } from "../../redux/usersSlice";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginWithEmail, setLoginWith] = React.useState(false);
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: {},
  });
  const { errors } = formState;

  //Current Url
  const url = localStorageInstance.getItem("redirectLocation") || "/board";

  /**
   * Set details to local storage
   */
  const setDetails = (data) => {
    dispatch(user(data.user_data));
    localStorageInstance.setItem({
      key: "token",
      value: data.token.access_token,
    });
    localStorageInstance.setItem({
      key: "role",
      value: data.user_data.role,
    });
    localStorageInstance.setItem({
      key: "userId",
      value: data.user_data.user_id,
    });
  };

  /**
   * Create form data
   */
  function createFormData(data) {
    const formData = new FormData();
    formData.append("username", data.email);
    formData.append("password", data.password);
    return formData;
  }

  const submit = (data) => {
    /**
     * Login User
     */
    setApiError("");
    setLoading(true);
    const formData = createFormData(data);

    login(formData, loginWithEmail)
      .then((res) => {
        // console.log(res.data.data.responseData[0]);
        let data = res.data.data.responseData[0];
        setDetails(data);
        navigate(url);
      })
      .catch((err) => {
        console.log(err);
        if (err.code == "ERR_NETWORK") {
          setApiError("An unexpected error occurred. Please try again later");
        } else setApiError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onError(errors) {
    console.log(errors);
  }

  function changeLoginWith() {
    reset();
    setLoginWith((loginWith) => !loginWith);
    setApiError("");
  }

  return (
    <Box
      component="main"
      // sx={{ backgroundImage: `url(${BgSvg})` }}
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${GraphicElements})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      disableGutters={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "440px",
          justifyContent: "center",
        }}
      >
        <img src={Logo} alt="not found" />
        <Box
          component="form"
          onSubmit={handleSubmit(submit, onError)}
          noValidate
          sx={{
            marginTop: 4,
            padding: "40px",
            borderRadius: "16px",
            boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
            background: "#ffffff",
          }}
        >
          <Typography
            className="text-center black-primary-color"
            component="h1"
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold" }}
          >
            Welcome to Task Board
          </Typography>
          {apiError && <Alert severity="error">{apiError}</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={loginWithEmail ? "Email Address" : "Username"}
            name="email"
            error={errors?.email ? true : false}
            helperText={errors?.email && errors.email.message}
            autoComplete="email"
            autoFocus
            {...register("email", {
              required: "Email is required",
            })}
            className="primary-input-field"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={errors?.password ? true : false}
            helperText={errors?.password && errors.password.message}
            autoComplete="current-password"
            {...register("password", {
              required: "Password is required",
            })}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="btn-blue"
            sx={{ mt: "14px", padding: "13px 16px" }}
            disabled={loading}
          >
            Sign In
          </Button>
          <Divider sx={{ mt: "24px", mb: "24px", color: "#919EAB" }}>
            OR
          </Divider>
          <Button
            fullWidth
            variant="outlined"
            className="btn-secondary-border"
            sx={{
              padding: "13px 16px",
            }}
            onClick={changeLoginWith}
          >
            {loginWithEmail ? "Login with LDAP" : "Login with Client"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
