import { useQuery } from "@tanstack/react-query";
import { getEpic} from "../../sevices/apiEpic";
import { getUserBoardEpic } from "../../sevices/apiEpic";

export function useEpic(board_id) {
    const {
      isLoading,
      data: epics,
      error,
      isError,
    } = useQuery({
      queryKey: [`epic`, board_id],
      queryFn: () => getEpic(board_id),
    });
  
    return { isLoading, error, epics, isError };
  }


export function useUserBoardEpic(id) {
  const {
    isLoading,
    data: epic,
    error,
    isError,
  } = useQuery({
    queryKey: [`userepic`, id],
    queryFn: () => getUserBoardEpic(id),
  });

  return { isLoading, error, epic, isError };
}
