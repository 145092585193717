import { Box } from "@mui/material";
import React from "react";
import Header from "./header";
import Workflow from "./KanbanBoard";
import { localStorageInstance } from "../../../utils/localStorage";
import { useLoginUser } from "../../../utils/reactQuery/users";
import { useDispatch } from "react-redux";
import { user } from "../../../redux/usersSlice";
import { useSelector } from "react-redux";
import Worklog from "../Worklog";

function TaskDetail() {
   const userId = localStorageInstance.getItem("userId");
   const { data, isLoading } = useLoginUser(userId);
   const dispatch = useDispatch();
   const open = useSelector((state) => state.search.taskTab);

   if (data) {
     dispatch(user(data.data.data.responseData));
   }
  return (
    <>
      {!isLoading && (
        <Box>
          {open ? (
            <>
              <Header />
              <Workflow />
            </>
          ) : (
            <>
              <Worklog/>
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default TaskDetail;