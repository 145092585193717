import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DialogCreateBoard from "./DialogCreateBoard";
import Vector from "../../../styles/svg/create-board.svg";

const CreateButtonText = () => {
  return (
    <div className="d-flex text-align-center justify-content-center ">
      <img src={Vector} />
      <p className="ml-2 font-bold">Create Board</p>
    </div>
  );
};

function CreateBoard() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="sidebar-create-board">
      <ListItem>
        <ListItemButton
          className="button-create-board text-center color-white button-create-board"
          onClick={handleClickOpen}
        >
        
          <ListItemText primary={<CreateButtonText />} />
        </ListItemButton>
      </ListItem>
      {open && <DialogCreateBoard open={open} handleClose={handleClose} />}
    </div>
  );
}

export default CreateBoard;
