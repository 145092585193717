import React, { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";

import Avatar from "../../../../../components/Avatar";
import { localStorageInstance } from "../../../../../utils/localStorage";
import { createAvtarName } from "../../../../../utils/helpers";
import { useBoardUsers } from "../../../../../utils/reactQuery/users";
import { useParams } from "react-router-dom";
import ConfirmationDialog from "../../../../../components/Dialog/ConfirmationDialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBoard } from "../../../../../sevices/apiBoards";
import toast from "react-hot-toast";
import { isAdmin } from "../../../../../utils/constants";

function AssignBoardOwner({ boardName }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const open = Boolean(anchorEl);
  //   const user = localStorageInstance.getItem("userData");

  const { isLoading, error, data, isError } = useBoardUsers(id);

  // console.log("assign board admin - isLoading", isLoading);
  // console.log("assign board admin - loading", loading);
  useEffect(() => {
    const sortedUsers = data?.data?.data?.responseData
      ? data?.data?.data?.responseData.sort(function (a, b) {
          return a.username.localeCompare(b.username);
        })
      : [];

    const index = sortedUsers.filter(
      (user) => user.user_id == boardName?.data?.responseData?.owner_user_id
    );
    let user = sortedUsers;
    if (index.length) {
      user = sortedUsers.filter(
        (user) => user.user_id !== boardName?.data?.responseData?.owner_user_id
      );
      user.unshift(index[0]);
    }
    setUsers(user);
    // console.log("assign board admin");
  }, [boardName, data, isLoading, id]);

  const handleClick = (event) => {
    if (boardName?.data?.responseData?.is_owner || isAdmin())
      setAnchorEl(event.currentTarget);
  };
  const handleClose = (action) => {
    setAnchorEl(null);
  };

  const { mutate: assignBoardMutate } = useMutation({
    mutationFn: updateBoard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`boardName`, id],
      });
      setLoading(false);
      // console.log(res);
      //    reset();
      toast.success(res.data.message);
      setOpenDialog(false);
    },
    onError: (error) => {
      console.error("Error assig board", error);
      toast.error(error.response.data.message);
      setLoading(false);
      setOpenDialog(false);
    },
  });

  const callback = () => {
    setLoading(true);
    assignBoardMutate({
      board_id: id,
      data: { owner_user_id: userId.user_id },
    });
  };

  const assignUser = (id) => {
    setOpenDialog(true);
    setUserId(id);
  };
  // console.log(boardName?.data?.responseData, "boardName?.data?.responseData");
  // board_owner_profile_color;
  return (
    <>
      {openDialog && (
        <ConfirmationDialog
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          message={`Are you sure to assign ${userId.username} as Board Owner?`}
          callback={callback}
          loading={loading}
          btnTitle={"Assign"}
          title={"Assign Board Owner"}
        />
      )}
      <div
        onClick={handleClick}
        className={
          boardName?.data?.responseData?.is_owner || isAdmin()
            ? "board-owner-avtar-click"
            : ""
        }
      >
        <Avatar
          key={`user-avtar-${boardName?.data?.responseData?.owner_user_id}`}
          username={boardName?.data?.responseData?.board_owner_username}
          user_image={boardName?.data?.responseData?.board_owner_image}
          title={boardName?.data?.responseData?.board_owner_username}
          className={"header-avatar user-avatar"}
          isAdmin={boardName?.data?.responseData?.is_owner}
          style={{
            bgcolor: boardName?.data?.responseData?.board_owner_profile_color?.background,
            color: boardName?.data?.responseData?.board_owner_profile_color?.text,
          }}
        >
          {createAvtarName(
            boardName?.data?.responseData?.board_owner_username || ""
          )}
        </Avatar>
      </div>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        className="assign-board-owner-list"
      >
        {users.map((el) => {
          const convertedImage = process.env.REACT_APP_BASE_URL + el.user_image;
          return (
            <MenuItem
              key={`user-avtar-dropdown-admin-assign-${el.user_id}`}
              onClick={() => assignUser(el)}
              className={
                boardName?.data?.responseData?.owner_user_id == el.user_id
                  ? "active-assign"
                  : ""
              }
            >
             
                <Avatar
                  key={`user-avtar-${el.user_id}`}
                  username={el.username}
                  user_image={el.user_image}
                  title={el.username}
                  className="user-avtar-dropdown user-avatar mr-8"
                  style={{
                    bgcolor: el?.profile_color?.background,
                    color: el?.profile_color?.text,
                  }}
                >
                  {createAvtarName(el.username)}
                </Avatar>
           
              {el.username}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default AssignBoardOwner;
