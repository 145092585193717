import React, { useState,useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  Button,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControl,
  Select,
  Divider
} from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import apicIcon from "../../../../../styles/svg/epic-icon.svg";
import CreateEpic from "./CreateEpic"
import './epicbutton.scss'
import { useParams } from 'react-router-dom';
import { useEpic } from '../../../../../utils/reactQuery/epics';
import { searchCard } from '../../../../../redux/EpicSearchSlice';
import { useBoardName } from '../../../../../utils/reactQuery/boards';



const EpicButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [epic, setEpic] = useState([]);
  const [checked, setChecked] = useState(false)
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  
  const {id} = useParams()
  const { boardName } = useBoardName(id);
  const boardNameTitle = boardName?.data?.data?.responseData?.board_name.substring(0,3).toUpperCase()

  const {
    isLoading,
    epics,
    isError
  } = useEpic(id)

 const names  = epics?.data?.data?.responseData || []
 console.log("names",names)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (id) => {
    // setChecked((prev)=>!prev)
    setEpic((prevEpic) =>
      prevEpic.includes(id)
        ? prevEpic.filter((n) => n !== id)
        : [...prevEpic, id]
    );
    // console.log("DISPATCH",epic)
    // dispatch(searchCard(epic))
  };

  const handleSelectAll = () => {

    // setChecked((prev)=>!prev)
    if (epic.length === names.length) {
      setEpic([]);
    } else {
      setEpic(names.map(name => name.epic_id));
    }
    // dispatch(searchCard(epic))
  };

  const isSelectAllChecked = epic.length === names.length;

  useEffect(() => {
    dispatch(searchCard(epic));
  }, [epic, dispatch]);

  // const stopPropagation = (event) => {
  //   event.stopPropagation();
  // };
  
// console.log("EPIC",personName)
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        id="epics-board-dropdown"
        aria-controls={open ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        className="white-button epics-btn"
        sx={{
          height: "38px",
          "&:hover": {
            outline: "none",
            backgroundColor: "rgb(240 249 254)",
            border: "1px solid #1b3e61",
          },
        }}
      >
        <img
          src={apicIcon}
          className="header-filter-icon"
          alt="not found"
        />{" "}
        &nbsp; <p className="header-action-button-text">All Epics</p>
      </Button>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
            
          elevation: 0,
          sx: {
            overflow: "visible",
            
           
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            width: "222px",
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        // className="epic-list"
      >
         <div style={{ maxHeight: "222px", overflowY: "auto" }}>
          <MenuItem
          // onClick={handleSelectAll}
          sx={{
            height: '30px', // Adjust the height as needed
            minHeight: '36px', // Ensure minimum height is set
          }}
        >
          {/* <Checkbox  checked={isSelectAllChecked} indeterminate={epic.length > 0 && epic.length < names.length} />
          <ListItemText  className='listItem-text'  primary="Select All Epics" /> */}


               <label class="custom-checkbox major-div-select-All" >
                <input
                  type="checkbox"
                  onClick={handleSelectAll}
                  // id={`epic-list-checkbox-${index}`}
                  checked={isSelectAllChecked}
                  indeterminate={epic.length > 0 && epic.length < names.length}
                  
                />
                <span class="checkmark"></span>
                <div className="epic-name-id-div-select-All">
                <p className="epic-name">
                  Select All Epics
                </p>
                </div>
              </label>



        </MenuItem>
        <Divider />
       {names?.map((item, index) => (
          <React.Fragment key={item?.epic_id}>
            <MenuItem    sx={{
                height: '40px', // Adjust the height as needed
                minHeight: '36px', // Ensure minimum height is set
                padding: '12px 8px !important '
              }}>
              {/* <Checkbox className='epic-checkbox' checked={epic.indexOf(item?.epic_id) > -1} />
              <div className="epic-list">
              <ListItemText className='listItem-text'  primary={item?.epic_name} />
                <p>{`${boardNameTitle} - ${item?.epic_id}`}</p>
                </div> */}

                <label class="custom-checkbox major-div" >
                <input
                  type="checkbox"
                  onClick={() => handleCheckboxChange(item?.epic_id)}
                  id={`epic-list-checkbox-${index}`}
                  checked={epic.indexOf(item?.epic_id) > -1}
                />
                <span class="checkmark"></span>
                <div className="epic-name-id-div">
                <p className="epic-name">
                {item?.epic_name}
                </p>
                <p className='epic-id'>
                {`${boardNameTitle} - ${item?.epic_id}`}
                </p>
                </div>
              </label>


            </MenuItem>
            {index < names.length - 1 && <Divider />}
          </React.Fragment>
        ))}

        <CreateEpic/>
        </div>
      </Menu>
    </>
  );
}

export default EpicButton;
