import Button from '@mui/material/Button';
import React,{useState} from 'react'
import clock from '../../../../../styles/svg/clock.svg'
import AddWorklogCustomizedDialogs from '../addWorklog/addWorklogDialog';
import './noWorklog.scss'
const NoWorklog = ({cardId}) => {
    const [addworklog, setAddWorklog] = useState(false)
    const handleAddWorklog = ()=>{
        setAddWorklog(true)
    }
  return (
    <>
      {addworklog && (
        <AddWorklogCustomizedDialogs
          cardId={cardId}
          addWorklog={addworklog}
          setAddWorklog={setAddWorklog}
          heading={"Add Work Log"}
          buttonName={"Save"}
        />
      )}
    <div className="noWorklog">
        <img src={clock} alt="image" />
        <p>
            No Work has been logged for this issue Yet logging work lets you track and report on the time spent on issue.
        </p>
        
        <div className='noWorklog-add-worklog-btn' onClick={handleAddWorklog}><Button 
         sx={{
          "&:hover": {
            backgroundColor: "#1B3E61",
            border: "none",
          },
        }}
    variant="contained">Add Work Log</Button></div>
    </div>
    </>
  )
}

export default NoWorklog