import React from "react";
import User from "./User";
import Filter from "./Filter";

function Header() {
  return (
    <div className="d-flex justify-content-space-between fixed-ui">
      <User />
      <Filter />
    </div>
  );
}

export default Header;
