import { useQuery } from "@tanstack/react-query";
import { getUser, getUsers } from "../../sevices/apiUser";
import { getBoardUsers } from "../../sevices/apiBoards";

export function useUsers() {
  const {
    isLoading,
    data: users,
    error,
    isError,
  } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });

  return { isLoading, error, users, isError };
}

export function useBoardUsers(id) {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: [`boardUsers`, id],
    queryFn: () => getBoardUsers(id),
  });

  return { isLoading, error, data, isError };
}


export function useLoginUser(id) {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: ['me'],
    queryFn: ()=> getUser(id),
  });
  return { isLoading, error, data, isError };
}