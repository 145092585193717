import React from "react";
// import RouterErrorPage from "../../Layouts/Router/RouterErrorPage";
import Login from "../../pages/auth/Login";
import Protected from "./Protected";

export const Auth = {
  element: <Protected />,
  //   errorElement: <RouterErrorPage />,
  children: [
    {
      element: <Login />,
      path: "/",
    },
  ],
};
