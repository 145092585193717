import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useBoardName } from '../../../../utils/reactQuery/boards'
import { useCard } from '../../../../utils/reactQuery/cards'
import CustomizedDialogs from '../details'

const OpenCardDetail = () => {
  const [showDetails,setShowDetails] = useState(false)
    const [searchParams] = useSearchParams()
    const { id } = useParams();
    const cardId = searchParams.get('card_id')
    // console.log("CARDDETAILS...",data)
    // console.log("title",data.title);
    const { boardName } = useBoardName(id);
    const boardNameTitle = boardName?.data?.data?.responseData?.board_name.substring(0,3).toUpperCase()
    
    
    useEffect(() => {
      if(cardId){
        setShowDetails(true)
      }
      else{
        setShowDetails(false)
      }
    }, [cardId]);
    
    
    // console.log("OPEN...",cardId ,searchParams.get('card_id'))
  return (
    <div>
        {cardId && <CustomizedDialogs
            //   status={status}
              boardNameTitle={boardNameTitle}
              cardId={cardId}
              // title={project.title}
              // cardId={cardId}
              // description={project.description}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
            /> }
    </div>
  )
}

export default OpenCardDetail