import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeBoardList:[],
};

const activeBoard = createSlice({
  name: "activeBoard",
  initialState,
  reducers: {
    setActiveBoards: {
      prepare(activeBoardList) {
        return {
          payload: { activeBoardList },
        };
      },
      reducer(state, action) {
        state.activeBoardList =
          action.payload.activeBoardList;
      },
    },
  },
});

export const { setActiveBoards } = activeBoard.actions;

export default activeBoard.reducer;
