import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { notifyManager } from '@tanstack/react-query';
import './sidebar.scss'
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: 180,
  height: 8,
  backgroundColor: 'rgba(242, 244, 246, 1)',
  marginRight: 10,
  borderRadius: 4,
  // transform:'none',
//   [`& .${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: '#1a90ff',
//   },
  // [`& .${linearProgressClasses.bar}`]: {
  //   borderRadius: 4,
  //   // backgroundColor: '#1a90ff', // default color
  // },
  '& .MuiLinearProgress-bar': {
    borderRadius: 4, // Resetting the transform property
    // transform: 'none'
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: 'green', // Setting the color of hours spent to green
    
  },
  '& .MuiLinearProgress-barColorSecondary': {
    backgroundColor: 'red', // Setting the color of hours exceeded to red
    
  },
}));
// const totalHours = 200
// const spentHours = 200
export default function CustomizedProgressBars({allocatedHours, hoursSpent}) {
  // Calculate the percentage of spent hours
  // const percentage = Math.round( (hoursSpent / allocatedHours) * 100) ;
  const percentage = allocatedHours && hoursSpent ? Math.min(Math.round((hoursSpent / allocatedHours) * 100), 100) : 0;

  // Determine the color of the progress bar based on spent hours
  // const limitExceeded = spentHours > totalHours;
  // const progressColor =  hoursSpent > allocatedHours ? 'secondary' : 'primary';
  const progressColor = hoursSpent && allocatedHours && hoursSpent > allocatedHours ? 'secondary' : 'primary';

  return (
    <>
    <Box display="flex" alignItems="center" >
      <BorderLinearProgress  variant="determinate" value={percentage > 100 ? 100 : percentage} color={progressColor} style={{marginRight:'2px'}}/>
    </Box>
    <Box display="flex" justifyContent='end' marginRight='10px' marginTop='5px' alignItems="center">
    <p className='complete-percent'>{percentage}% completed</p>
  </Box>
  </>
  );
}
