import React from "react";
import { useParams } from "react-router-dom";
import { useBoardName } from "../../utils/reactQuery/boards";
import BoradHeader from "./components/BoardHeader/BoradHeader";
import Workflows from "./components/Kanban/Workflows";
import { useDispatch } from "react-redux";
import { localStorageInstance } from "../../utils/localStorage";
import { useLoginUser } from "../../utils/reactQuery/users";
import { user } from "../../redux/usersSlice";
import Loader from "../../components/Loader";

function BoardPage() {
   const userId = localStorageInstance.getItem("userId");
   const { data, isLoading } = useLoginUser(userId);
  const { id } = useParams();
  // console.log(id);
  const { boardName } = useBoardName(id);
  

     const dispatch = useDispatch();
    
     if (data) {
       dispatch(user(data.data.data.responseData));
     }
  
  return (
    <>
      {isLoading ? (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      ) : (
        <>
          <BoradHeader
            boardName={boardName?.data}
            boardId={id}
            ownerId={boardName?.data?.data?.responseData?.owner_user_id}
          />
          {boardName?.data?.data?.responseData?.workflow_id && (
            <Workflows
              boardId={id}
              workflowId={boardName?.data?.data?.responseData?.workflow_id}
              boardName={boardName?.data?.data?.responseData?.board_name}
            />
          )}
        </>
      )}
    </>
  );
}

export default BoardPage;
