import React from 'react'
import Header from './components/Header'
import './worklog.scss';
import Filters from './components/Filters';
import History from './components/History';

function Worklog() {
  return (
    <>
    <Header/>
    <Filters/>
    <History/>
    </>
  )
}

export default Worklog