import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Select from "@mui/material/Select";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { updateCard } from "../../../../../sevices/apiCards";
import toast from "react-hot-toast";
import './ChangeStatus.scss'
export default function ChangeStatus({ currentStatusId, cardId, checkCondition }) {
  console.log("Rendering ChangeStatus component");
  const [status, setStatus] = React.useState("");
  const { id } = useParams();
  const queryClient = useQueryClient();

  console.log("useParams id:", id);
  console.log("currentStatusId:", currentStatusId);
  console.log("cardId:", cardId);

  const cachedData = queryClient.getQueryData(["workflow", id]);
   console.log("cachedData",cachedData)
  
  React.useEffect(() => {
    console.log("useEffect running, setting status to", currentStatusId);
    setStatus(currentStatusId);
  }, [currentStatusId]);
    //  console.log("checkCondition",checkCondition)
  const { mutate: updateMutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      console.log("Mutation success:", res);
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      
      // setLoading(false);
      toast.success(res.data.message);
      //  handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      // toast.error(error.response.data.message);
      // setLoading(false);
      //  handleCloseDialog();
    },
  });

  const handleChange = (event) => {
    console.log("handleChange event:", event.target.value);
    updateMutate({
      card_id: cardId,
      data: { workflow_step_id: event.target.value },
    });
    setStatus(event.target.value);
  };

  const showStatus = cachedData?.data?.data?.responseData?.filter((el)=>el.workflow_step_id == currentStatusId )[0]
  console.log("status",showStatus?.status)
  return (
    <Box sx={{ 
    }}>
      {
        checkCondition ? (<FormControl  size="small" className="card-status-dropdown-parent">
        {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          //   id="demo-simple-select"
          value={status}
          //   label="Age"
          onChange={handleChange}
          className="card-status-dropdown"
        >
          {cachedData?.data.data.totalRecord &&
            cachedData?.data.data.responseData.map((el, index) => (
              <MenuItem value={el.workflow_step_id}>{el.status}</MenuItem>
            ))}
        </Select>
      </FormControl>) : (<div className="project-status d-flex justify-content-center align-items-center">
                <button className="project-status-button">{showStatus?.status}</button>
                <div className="project-status-icon">
                  {/* <KeyboardArrowDownIcon/> */}
                </div>
              </div>)
      }
      
      
    </Box>
  );
}
