import { ListItem, Tooltip, Typography } from "@mui/material";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import SettingIcon from "../../../styles/svg/clarity_administrator-solid.svg";
import Avatar from "../../../components/Avatar/index";
import { createAvtarName } from "../../../utils/helpers";
import { searchCard } from "../../../redux/SearchSlice";
import { useDispatch } from "react-redux";
import { filterUser } from "../../../redux/usersSlice";
export default function SidebarListItem({ board }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  function handleClick() {
    if (id != board.board_id) {
      dispatch(searchCard(""));
      dispatch(filterUser(null));
    }
  }

  return (
    <ListItem key={board.board_id} className="menu-ListItem">
      <NavLink to={`/board/${board.board_id}`} onClick={handleClick}>
        <Avatar
          key={`board-avtar-${board.board_id}`}
          user_image={board.board_owner_image}
          className="sidebar-board-owner user-avatar"
          title={board.board_owner_name}
          style={{
            bgcolor: board?.board_owner_profile_color?.background,
            color: board?.board_owner_profile_color?.text,
          }}
        >
          {`${createAvtarName(board.board_owner_name)}`.toUpperCase()}
        </Avatar>

        {/* <Tooltip title={board.board_name}> */}
          <p className="board-name-text">{board.board_name}</p>
        {/* </Tooltip> */}
        {board.is_owner ? (
          <img src={SettingIcon} alt="not found" className="setting-icon-img" />
        ) : null}
      </NavLink>
    </ListItem>
  );
}
