import React from "react";
import Boards from "../Filters/Boards";
import Epics from "../Filters/Epics";
import SelectDatePicker from "../Filters/SelectDatePicker";
import Worklog from "./Worklog";
import { useSelector } from "react-redux";

function Filter() {
      const checkedList = useSelector((state) => state.search.boardSearch);
  return (
    <div className="d-flex align-items-center">
      <p className="filter-text">Filter By:</p>
      <Boards />
      {checkedList && checkedList.length == 1 && <Epics/>}
      <SelectDatePicker/>
      <Worklog/>
    </div>
  );
}

export default Filter;
