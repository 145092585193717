import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useParams, useSearchParams } from "react-router-dom";

import CheckBox from "../../../../styles/svg/id-icon.svg";
// import AssignCard from "./AssignCard";
import { isAdmin } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import Avatar from '../../../../components/Avatar'
import EditTitle from "../../../board/components/Kanban/EditTitle";
import { Tooltip } from "@mui/material";

function KanbanCard({ project : card,  index, id }) {
  const { id: boardId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [project,setProject]= useState(card)
  // const searchParams = new URLSearchParams(useSearchParams());
  // const setSearchParams = useSearchParams();
  const [collapsed, setCollapsed] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const currentUser = useSelector((state) => state.users.logInUser);
  const queryClient = useQueryClient();
  // const cachedData = queryClient.getQueryData(["boardName", boardId]);
  //   const queryClient = useQueryClient();
  //   const cachedData = queryClient.getQueryData({ queryKey: ["todos"] });
  // console.log("project", project);
  const tooltipName = `${project.card.assigned_to_user?.first_name || ""} ${
    project.card.assigned_to_user?.last_name || ""
  }`;
  const firstName = project.card.assigned_to_user?.first_name[0];
  const lastName = project.card.assigned_to_user?.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  //project.card.assigned_to_user

  const handleDetails = (e) => {
    // e.stopPropagation();
    // setCardId(project.card.card_id);
    // console.log("searchParams",project.card?.card_id);
    // console.log("searchParams",project.card);
    // setShowDetails(true);
    // setSearchParams({'card_id': project.card?.card_id })
    updateSearchParams("card_id", project.card?.card_id);
  };

  const accessDragCard =
    currentUser?.user_id == project.card?.assigned_to_user_id ||
    currentUser?.user_id == project.card?.reporter ||
    currentUser?.user_id == project.board_admin ||
    isAdmin();

  const accessForAssignOrEditTitle =
    currentUser?.user_id == project.card?.reporter ||
    currentUser?.user_id == project.board_admin ||
    isAdmin();

  const showEditButton = () => {
    if (accessForAssignOrEditTitle) setHoveredIndex(true);
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const callback = (value) => {
    setProject((val)=>{
      return {
        ...val,
        card: {
          ...val.card,
          title: value,
        },
      };
    })
  };

  return (
    <Draggable
      key={id.toString()}
      draggableId={project.card.card_id.toString()}
      index={index}
      isDragDisabled={!accessDragCard}
    >
      {(provided, snapshot) => (
        <>
          <Tooltip title={project.board_name}>
            <div
              className="card-style cursor-pointer"
              // draggable={true}
              // onDragEnd={(e) => {
              //   onDragEnd(e, project.card);
              // }}
              snapshot={snapshot}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              onClick={handleDetails}
              //  onClick={()=>{setSearchParams('card_id', project.card?.card_id )
              //  handleDetails()
              // }}
              onMouseEnter={showEditButton}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className="d-flex">
                {/* <p className="card-title-text">{project.card.title}</p> */}
                <EditTitle
                  project={project.card}
                  hoveredIndex={hoveredIndex}
                  callback={callback}
                />
              </div>
              {collapsed ? null : (
                <div>
                  <strong>Description: </strong>
                  {project.card.description}
                  <br />
                </div>
              )}
              <div className="d-flex">
                {project.card.labels.map(
                  (el) =>
                    el != "" && (
                      <div className="card-level-tag mr-1">
                        <p className="card-level-tag-text">{el}</p>
                      </div>
                    )
                )}

                {project.card?.epic && (
                  <div className="d-flex">
                    <div className="card-epic-tag mr-1">
                      <p className="card-epic-tag-text">
                        {project.card?.epic?.epic_name}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="card-bottom d-flex align-items-center justify-content-space-between">
                <div>
                  <p className="d-flex align-items-center">
                    <img src={CheckBox} className="mr-1" />{" "}
                    <p className="card-id-number">
                      {`${project.board_name.substring(0, 3)}`.toUpperCase()}-
                      {project.card.card_id}
                    </p>
                  </p>
                </div>
                <div>
                  {/* <AssignCard
                  tooltipName={tooltipName}
                  project.card={project.card}
                  name={name}
                  accessForAssign={accessForAssignOrEditTitle}
                /> */}
                  {project.card?.assigned_to_user_id ? (
                    <Avatar
                      title={tooltipName}
                      className={"card-avtar header-avatar"}
                      user_image={project.card?.assigned_to_user?.user_image}
                      style={{
                        bgcolor:
                          project.card?.assigned_to_user?.profile_color
                            ?.background,
                        color:
                          project.card?.assigned_to_user?.profile_color?.text,
                      }}
                    >
                      {name}
                    </Avatar>
                  ) : (
                    <Avatar
                      unassigned={true}
                      title={"Unassigned"}
                      className={"card-avtar header-avatar"}
                      // user_image={NotAssignCardIcon}
                    />
                  )}
                </div>
              </div>
            </div>
          </Tooltip>
        </>
      )}
    </Draggable>
  );
}

export default KanbanCard;
