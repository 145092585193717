import React from "react";
import BoardPage from "../../pages/board";
import CheckBoardAvailability from "../../pages/board/CheckBoardAvailability";
import Dashboard from "../../pages/dashboard";
import Private from "./Private";
import TaskDetail from "../../pages/myTask/components/TaskDetail";

export const Pages = {
  element: <Private />,
  children: [
    {
      element: <Dashboard />,
      //   errorElement: <RouterErrorPage />,
      children: [
        {
          path: "/board",
          element: <CheckBoardAvailability />,
        },
        {
          path: "/board/:id",
          element: <BoardPage />,
        },
        {
          path: "/my-task",
          element: <TaskDetail />,
        },
      ],
    },
  ],
};
