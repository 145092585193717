import {
  Button,
  Menu,
  MenuItem,
  AppBar,
  Box,
  Grid,
  ListItemIcon,
  Typography,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import Logo from "../../styles/svg/sofmenLogo.svg";
import { localStorageInstance } from "../../utils/localStorage";
import "./header.scss";
import Search from "../Search";
import Avatar from "../Avatar";
import LogoutIcon from "../../styles/svg/tabler_logout.svg";
import { useSelector } from "react-redux";
import UploadImage from './uploadImage'
export default function Default() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.users.logInUser);
  const [showUpdateImage, setShowUpdateImage] = useState(true)
  const userName = Object.hasOwn(
    user,
    "first_name"
  ) && `${user.first_name[0]}${user.last_name[0]}`.toUpperCase();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log("even target === ",event.currentTarget);
  };
  // const handleUpdateImageClose=()=>{
  //   setAnchorEl(null);
  // }
  const handleClose = (action) => {
    // console.log("close === ", action, showUpdateImage)
    if (action === "logout") {
      localStorageInstance.clear();
      queryClient.clear();
      navigate(`/`);
    }
    // else if (action === "uploadImage") {
      
    // } else {
    //   if(showUpdateImage==true){

       else{
         setAnchorEl(null);
      }
     
      // setShowUpdateImage(true)
      // console.log("OPEN")
    
  };
  // console.log("USER",user)
  return (
    <>
    {/* {showUpdateImage && <UploadImage user={user} userName={userName} />} */}
    <AppBar className="app-header" position="fixed" open={true}>
      <Grid container alignItems="center">
        <Grid item xs={7}>
          <div className="logo d-flex">
            <img src={Logo} className="board-logo" alt="Sofmen" />
          </div>
        </Grid>
        <Grid item xs={5} className="">
          <div className="header-right">
            <Box sx={{ mr: 2 }}>
              <Search />
            </Box>
            {Object.hasOwn(user, "first_name") && (
              <Box>
                <Button
                  id="user-profile-button"
                  aria-controls={open ? "user-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  endIcon={
                    <KeyboardArrowDownIcon className="profile-down-arrow" />
                  }
                  className="pa-0"
                >
                  <Avatar
                    title={`${user.first_name} ${user.last_name}`}
                    user_image={user?.user_image}
                    className="user-avatar"
                    style={{
                      bgcolor: user?.profile_color?.background,
                      color: user?.profile_color?.text,
                    }}
                  >
                    {userName}
                  </Avatar>
                </Button>

                <Menu
                  id="account-menu"
                  className="profile-header"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  // onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 14,
                        height: 14,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem  >
                    {/* <Avatar
                      user_image={user?.user_image}
                      title={`${user?.first_name} ${user?.last_name}`}
                      className="user-avatar"
                      style={{
                        bgcolor: user?.profile_color?.background,
                        color: user?.profile_color?.text,
                      }}
                    >
                      {userName}
                    </Avatar> */}
                
                    <UploadImage user={user} userName={userName} setAnchorEl={setAnchorEl}/>

                   
                    <ListItemText
                   
                      primary={
                        <React.Fragment>
                          <Typography className="profile-dropdown-name">
                            {user.first_name} {user.last_name}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography className="profile-dropdown-email">
                            {user.email}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </MenuItem>
                  {/* <Divider /> */}
                  <MenuItem onClick={() => handleClose("logout")}>
                    <ListItemIcon>
                      <img src={LogoutIcon} />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </div>
        </Grid>
      </Grid>
    </AppBar>
    </>
  );
}
