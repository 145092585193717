import React, { useState } from "react";
import Divider from "@mui/material/Divider";

import VectorArchived from "../../../styles/svg/Vector-archived.svg";
import Vector from "../../../styles/svg/Vector.svg";
import AccordionNav from "./Accordion";
import CreateBoard from "./CreateBoard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeExpand } from "../../../redux/boardListSlice";
import { isAdmin } from "../../../utils/constants";
import MyTask from "../../myTask";

function BoardList({ boards }) {
  const dispatch = useDispatch();
  // Redux state
  const expanded = useSelector((state) => state.boardList.expanded);

  const handleChange = (panel) => (event, isExpanded) => {
    // setExpanded(isExpanded ? panel : null);
    dispatch(changeExpand(isExpanded ? panel : null));
  };
  return (
    <>
      <div
        className={`sidebar-board-list ${
          isAdmin()
            ? "sidebar-board-list-for-create-button"
            : "sidebar-board-list-for-not-create-button"
        }`}
      >
        <MyTask />
        <Divider />
        <AccordionNav
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          heading={"Active Boards"}
          icon={Vector}
          listing={
            boards
              ? boards
                  .filter((el) => !el.is_archive)
                  .sort(function (a, b) {
                    return a.board_name.localeCompare(b.board_name);
                  })
              : []
          }
          defaultExpanded={true}
          sx={{
            marginBottm: "0px",
          }}
          uniqueKey={"active-projects"}
        />
        <Divider />
        <AccordionNav
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          heading={"Archived Boards"}
          icon={VectorArchived}
          listing={
            boards
              ? boards
                  .filter((el) => el.is_archive)
                  .sort(function (a, b) {
                    return a.board_name.localeCompare(b.board_name);
                  })
              : []
          }
          uniqueKey={"inactive-projects"}
        />
      </div>
      {isAdmin() && <CreateBoard />}
    </>
  );
}

export default BoardList;
