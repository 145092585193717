import moment from "moment";
import React from "react";
import Loader from "../../../../../../components/Loader";
import { useHistory } from "../../../../../../utils/reactQuery/worklog";
import HistoryItem from "./HistoryItem";

function History({ cardId }) {
  const { isLoading, history, isError } = useHistory(cardId);

  // console.log("history", history);

  return (
    <>
      {isLoading ? (
        <div className="loader-center">
        <Loader color={"black"} />
        </div>
      ) : (
        <>
          <div>
            {history.data.data.responseData.sort((a, b) => {
                const dateA = moment(a.at_time);
                const dateB = moment(b.at_time);
                return dateB - dateA;
              }).map((el,i)=> <HistoryItem key={`historys-${i}`} history={el}/>)}
          </div>
        </>
      )}
    </>
  );
}

export default History;
