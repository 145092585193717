import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import edit from "../../../../../styles/svg/edit.svg";
import note from "../../../../../styles/svg/note.svg";
import AddWorklogCustomizedDialogs from "../../worklog/addWorklog/addWorklogDialog";
import CustomizedProgressBars from "./progressbar";
import Avatar from "../../../../../components/Avatar";
import { useCard } from "../../../../../utils/reactQuery/cards";
import { currentUserId, isAdmin } from "../../../../../utils/constants";
import Field from "../../../../../components/field";
import "./sidebar.scss";
import { TextField } from "@mui/material";
import toast from "react-hot-toast";
import { updateCard } from "../../../../../sevices/apiCards";
import { localStorageInstance } from "../../../../../utils/localStorage";
import ComboBox from "./components/multiselectSearch";
import CustomDateFormatter from "../../dateTimeFormateConverter/dateTimeFormateConverter";
import TimeDifferenceCalculator from "./components/DateTimeDifference";
import { differenceInDays } from "../../../../../utils/helpers";
import DatePickerSmall from "./components/datePicker";
import { useBoardName } from "../../../../../utils/reactQuery/boards";
import AssignCard from "../../Kanban/AssignCard";
import { useSelector } from "react-redux";
// import singleSelectDropdown from "./components/epic/singleSelectDropdown/singleSelectDropdown";
import Epic from "./components/epic/epic";
//  import DateTimePicker from '../../datePickerCalender/datePicker'
export default function SidebarGrid({ cardId }) {
  const [addWorklog, setAddWorklog] = useState(false);
  const [isAllocatedHours, setAllocatedHours] = useState(false);
  const [inputAllocatedHours, setInputAllocatedHours] = useState(null);
  const [label, setLabel] = useState();
  const [showLebel, setShowLebel] = useState(false);
  const [showEpic, setShowEpic] = useState(false);
  // const [selectEpic, setSelectEpic] = useState(null);
  const [date, setDate] = useState(null);
  const [dueDate, setDueDate] = useState(false);
  const queryClient = useQueryClient();
  const { id } = useParams();

  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);
  const data = card?.data?.data?.responseData;
  // console.log("DATA/////",data?.labels[0])
  useEffect(() => {
    setInputAllocatedHours(data?.allocated_hours);
    setLabel(data?.labels || []);
    setDate(data?.due_date);
    return () => {};
  }, [card]);


  const user = useSelector((state) => state.users.logInUser);
  // console.log("image",user)
  const firstName = user.first_name[0];
  const lastName = user.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  const tooltipName = `${user.first_name || ""} ${user.last_name || ""}`;
  const assigneeFirstName = data?.assigned_to_user?.first_name[0];
  const assigneeLastImage = data?.assigned_to_user?.last_name[0];
  const assigneeName = `${assigneeFirstName || ""}${assigneeLastImage || ""}`;
  const assigneetooltipName = `${data?.assigned_to_user?.first_name || ""} ${
    data?.assigned_to_user?.last_name || ""
  }`;
  const reportertooltipName = `${data?.reported_by?.first_name || ""} ${
    data?.reported_by?.last_name || ""
  }`;
  const reporterHeadShotName = `${data?.reported_by?.first_name[0] || ""}${
    data?.reported_by?.last_name[0] || ""
  }`;

  // const createdAgoDate = <TimeDifferenceCalculator timestamp={data?.date_time_created}/>

  const { boardName } = useBoardName(id);
  const boardNameTitle = boardName?.data?.data?.responseData?.board_name
    .substring(0, 3)
    .toUpperCase();
  const boardOwnerId = boardName?.data?.data?.responseData?.owner_user_id;

  const conditionCheck =
    isAdmin() ||
    boardOwnerId === currentUserId() ||
    card?.data?.data?.responseData?.assigned_to_user_id === currentUserId() ||
    card?.data?.data?.responseData?.reporter === currentUserId();

  const { mutate: updateCardmutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`lebels`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      // setLoading(false);
      // console.log(res);
      //    reset();
      toast.success(res.data.message);
      setDueDate(false);
      setShowEpic(false)
      // handleClose();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      // setLoading(false);
      // handleClose();
    },
  });

  const handleInputBlur = () => {
    // console.log("inputAllocatedHours",inputAllocatedHours,data?.allocated_hours)
    if (data?.allocated_hours != inputAllocatedHours) {
      // console.log("inputAllocatedHours")
      updateCardmutate({
        card_id: cardId,
        data: {
          allocated_hours: inputAllocatedHours,
        },
      });
    } else {
    }
    setAllocatedHours(false);
  };
  const handleLabelKeyDown = (e, newLabel) => {
    let newLabelArray = label;
    if (newLabel.trim() !== "") {
      newLabelArray = [newLabel];
    } else if (newLabel == "") {
      // console.log('newLabel',typeof newLabel);

      newLabelArray = [];
    }
    if (e.key === "Enter" && (newLabel.trim() !== "" || newLabel === "")) {
      updateCardmutate({
        card_id: cardId,
        data: {
          labels: newLabelArray,
        },
      });
      setShowLebel(false);
    }
  };
  const handleDueDate = (e) => {
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      setDueDate(true);
    }
  };
  const handleDuedateBlur = (event) => { 
    setDueDate(event);
  };

  const handleShowEpic = ()=>{
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.assigned_to_user_id == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      
      setShowEpic(true)
    }
   
  }

  // const handleSelectEpic = (epic_name)=>{
  //   // setSelectEpic(epic_name)
  // }

// console.log("epic_name",selectEpic)

  const handleShowLebel = () => {
    
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.assigned_to_user_id == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      
      setShowLebel(true);
    }
    // console.log("label",showLebel)
  };

  const handleEpicBlur = (epic_id)=>{
    setShowEpic(false)
    if(data?.epic?.epic_id != epic_id ){
      updateCardmutate({
        card_id: cardId,
        data: {
          epic_id: epic_id,
        },
      });
    }
 
    
  }


  const handleLabelBlur = (e, newLabel) => {
    let newLabelArray = label;
    if (newLabel.trim() !== "") {
      newLabelArray = [newLabel];
    } else if (newLabel == "") {
      // console.log('newLabel',typeof newLabel);

      newLabelArray = [];
    }
    if (newLabel.trim() !== "" || newLabel === "") {
      updateCardmutate({
        card_id: cardId,
        data: {
          labels: newLabelArray,
        },
      });
      setShowLebel(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (data?.allocated_hours != inputAllocatedHours) {
        e.preventDefault();
        updateCardmutate({
          card_id: cardId,
          data: {
            allocated_hours: inputAllocatedHours,
          },
        });
      }

      setAllocatedHours(false);
    }
  };
  const handleLabelChange = (event, newValue) => {
    // console.log("lebels setter",newValue);
    setLabel(newValue);
  };
  const handleInputChange = (event, newInputValue) => {
    // Add the new input value to selectedValues when the user types something
    if (newInputValue.trim() !== "" && !label.includes(newInputValue)) {
      setLabel([...label, newInputValue]);
    }
  };
  const handleAllocatedHoursInput = () => {
    // console.log("object0",data?.reporter,currentUserId());
    if (
      isAdmin() ||
      data?.reporter == currentUserId() ||
      boardOwnerId == currentUserId()
    ) {
      // console.log("inside allocated hours");
      setAllocatedHours(true);
    }
  };
  const handleAssignClick = () => {
    updateCardmutate({
      card_id: cardId,
      data: {
        assigned_to_user_id: currentUserId(),
      },
    });
  };
  const handleInputHourChange = (e) => {
    setInputAllocatedHours(e.target.value);
  };
  const handleDueDateKeyDown = (newDate) => {
    console.log("newDate", newDate);
    // const utcStart = new moment(newDate, "YYYY-MM-DDTHH:mm").utc();
    // const newTime = moment(newDate).format("YYYY-MM-DD")
    // console.log("dateUTC", newTime);
    if (newDate) {
      updateCardmutate({
        card_id: cardId,
        data: {
          due_date: moment(newDate).format("YYYY-MM-DD"),
        },
      });
    } else {
      updateCardmutate({
        card_id: cardId,
        data: {
          due_date: 0,
        },
      });
    }
    // updateCardmutate({
    //   card_id: cardId,
    //   data:{
    //     due_date: moment(newDate).format('YYYY-MM-DD'),
    //   }
    // })
    // }
    setDueDate(false);
  };
  // const handleDueDateInput = ()=>{
  //   // updateCardmutate({
  //   //   card_id: cardId,
  //   //   data:{
  //   //     due_date: date,
  //   //   }
  //   // })
  // }
  // console.log("moment",moment.utc(data?.due_date).local().format("MM-DD-YYYY"));
  const handleWorklog = () => {
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.assigned_to_user_id == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      setAddWorklog(true);
    }
  };
  // const data = card?.data?.data?.responseData
  // console.log("data.due_date",data?.due_date )
  console.log("dueDate",dueDate)
  console.log("date",date)
  return (
    <>
      {addWorklog && (
        <AddWorklogCustomizedDialogs
          cardId={cardId}
          addWorklog={addWorklog}
          setAddWorklog={setAddWorklog}
          heading={"Add Work Log"}
          buttonName={"Save"}
        />
      )}
      <Box
        sx={{
          width: "364px",
          height: "445px",
          marginTop: "8px",
          marginLeft: "-20px",
          // position: "fixed",
        }}
      >
        <Grid container className="sidebar-block">
          <Grid item className="sidebar-heading">
            <div className="sidebar-heading-content">
              {/* <div className="add-parent">
                <img src={edit} alt="" />
                <span className="add-parent-content">Add parent</span>
                <span style={{ opacity: "0.25" }}>/</span>
              </div> */}
              <div className="note">
                <img src={note} alt="" />
                <p>{`${card?.data?.data?.responseData?.board?.board_name
                  .substring(0, 3)
                  .toUpperCase()} - ${data?.card_id}`}</p>
              </div>
            </div>
          </Grid>
          <Grid item className="sidebar-details">
            <Grid item className="sidebar-details-content">
              <Grid item className="sidebar-worklog">
                <div className="sidebar-worklog-detail">Details</div>
                <div className="sidebar-Addworklog-btn" onClick={handleWorklog}>
                  <Button sx={{}}>Add Log Work</Button>
                </div>
              </Grid>
              <Grid conatainer className="box">
                <Grid item className="allocated-hours">
                  <p>Allocated Hours</p>
                  <div onClick={handleAllocatedHoursInput}>
                    {isAllocatedHours ? (
                      <TextField
                        id="outlined-basic-input"
                        fullWidth
                        className="input-allocated"
                        // label={label}

                        size={"small"}
                        defaultValue={data?.allocated_hours}
                        variant="outlined"
                        onChange={handleInputHourChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleInputBlur}
                        autoFocus
                      />
                    ) : (
                      <div className="allocated-hours-input">
                        {data?.allocated_hours ? (
                          <p>{data?.allocated_hours}</p>
                        ) : (
                          <p className="due-date-input-allocate-hour-none">
                            None
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid conatiner className="box">
                <Grid item className="time-tracking">
                  <p>Time Tracking</p>
                  <div className="time-tracking-input">
                    <CustomizedProgressBars
                      allocatedHours={data?.allocated_hours}
                      hoursSpent={data?.total_hours_spent}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid conatainer className="box">
                <Grid item className="allocated-hours">
                  <p>Hours Spent</p>
                  <div className="allocated-hours-input">
                    <p>
                      {data?.total_hours_spent ? (
                        data?.total_hours_spent
                      ) : (
                        <p className="due-date-input-none">None</p>
                      )}
                    </p>
                  </div>
                </Grid>
              </Grid>
              <Grid container className="box">
                <Grid item className="allocated-hours">
                  <p>Assignee</p>
                  <div className="assignee-detail">
                    <div className="assignee-image">
                      <AssignCard
                        tooltipName={
                          data?.assigned_to_user
                            ? assigneetooltipName
                            : tooltipName
                        }
                        project={data}
                        name={data?.assigned_to_user ? assigneeName : name}
                        accessForAssign={conditionCheck}
                      />
                      {/* <Avatar
                    title={(data?.assigned_to_user) ? assigneetooltipName : tooltipName}
                      
                      className={" header-avatar add-comment-avtar"}
                      user_image={(data?.assigned_to_user) ? data?.assigned_to_user?.user_image : user.user_image}
                      style={(data?.assigned_to_user) ? ({
                        bgcolor: data?.assigned_to_user?.profile_color?.background,
                        color: data?.assigned_to_user?.profile_color?.text,
                      }) : ({
                        bgcolor: user?.profile_color?.background,
                        color: user?.profile_color?.text,
                      })}
                    >
                       {(data?.assigned_to_user) ? assigneeName : name}
                    </Avatar> */}
                    </div>
                    <div className="assignee">
                      <div className="assignee-name">
                        {/* {(data?.assigned_to_user) ? <p>{`${data?.assigned_to_user?.first_name} ${data?.assigned_to_user?.last_name}`}</p> : <p>{`${tooltipName}`}</p>} */}
                        {data?.assigned_to_user && (
                          <p>{`${data?.assigned_to_user?.first_name} ${data?.assigned_to_user?.last_name}`}</p>
                        )}
                      </div>
                      <div className="sidebar-assigntome-btn">
                        {data?.assigned_to_user ? null : (
                          <Button onClick={handleAssignClick} sx={{}}>
                            Assign to me
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid conatiner className="box">
                <Grid item className="allocated-hours">
                  {" "}
                  <p>Label</p>
                  {/* <div  > */}
                  {showLebel ? (
                    <ComboBox
                      onBlur={handleLabelBlur}
                      labels={label}
                      setLebelCallback={handleLabelChange}
                      handleInputChange={handleInputChange}
                      onKeyDown={handleLabelKeyDown}
                    />
                  ) : (
                    <div className="due-date" onClick={handleShowLebel}>
                      {" "}
                      <div className="due-date-input">
                        {" "}
                        {data?.labels.length !== 0 ? (
                          data?.labels
                        ) : (
                          <p className="due-date-input-none">None</p>
                        )}{" "}
                      </div>{" "}
                    </div>
                  )}
                  {/* </div> */}
                </Grid>
              </Grid>

              <Grid conatiner className="box">
                <Grid item className="allocated-hours">
                  {" "}
                  <p>Epic</p>
                  {/* <div  > */}
                  {showEpic ? (
                    // <ComboBox
                    //   onBlur={handleLabelBlur}
                    //   labels={label}
                    //   setLebelCallback={handleLabelChange}
                    //   handleInputChange={handleInputChange}
                    //   onKeyDown={handleEpicKeyDown}
                    // />
                    <Epic handleEpicBlur={handleEpicBlur} cardId={cardId} />
                  ) : (
                    <div className="due-date" onClick={handleShowEpic}>
                      {" "}
                      <div className="due-date-input">
                        {" "}
                        {data?.epic?.epic_name ? (
                          data?.epic?.epic_name
                        ) : (
                          <p className="due-date-input-none">None</p>
                        )}{" "}
                      </div>{" "}
                    </div>
                  )}
                  {/* </div> */}
                </Grid>
              </Grid>

              <Grid conatiner className="box">
                <Grid item className="allocated-hours">
                  {" "}
                  <p>Due Date</p>
                  {/* <div className='dueDate-box' onClick={handleDueDate} onBlur ={handleDuedateBlur}> */}
                  {dueDate  ? (
                    <div
                      className="due-Date-picker"
                      // onKeyDown={(event) => handleDueDateKeyDown(event)}
                    >
                      <DatePickerSmall
                        date={date}
                        handleDueDateKeyDown={handleDueDateKeyDown}
                        handleDuedateBlur={handleDuedateBlur}
                      />
                    </div>
                  ) : (
                    <div className="due-date" onClick={handleDueDate}>
                      {" "}
                      <div className="due-date-input">
                        {" "}
                        {data?.due_date ? (
                          // moment(data?.due_date).format("MM-DD-YYYY")
                          moment
                            .utc(data?.due_date)
                            .local()
                            .format("MM-DD-YYYY")
                        ) : (
                          // <CustomDateFormatter date={data?.due_date} dateOnly={true}/>
                          <p className="due-date-input-none">None</p>
                        )}{" "}
                      </div>{" "}
                    </div>
                  )}
                
                 
                  {/* <DatePickerSmall date={date}  handleDueDateKeyDown={handleDueDateKeyDown}  isValid={dueDate} /> */}
                 
        
                  {/* {<DatePickerSmall date={date} setDate={setDate} handleDueDateKeyDown={handleDueDateKeyDown}/>} */}
                </Grid>
              </Grid>

              <Grid conatiner className="box">
                <Grid item className="allocated-hours">
                  <p>Reporter</p>
                  <div className="reporter-detail">
                    <div className="reporter-image">
                      <Avatar
                        title={reportertooltipName}
                        className={" header-avatar add-comment-avtar"}
                        user_image={data?.reported_by?.user_image}
                        style={{
                          bgcolor: data?.reported_by?.profile_color?.background,
                          color: data?.reported_by?.profile_color?.text,
                        }}
                      >
                        {reporterHeadShotName}
                      </Avatar>
                    </div>
                    <div className="reporter-name">
                      {data?.reported_by ? (
                        <p>{`${data?.reported_by?.first_name} ${data?.reported_by?.last_name}`}</p>
                      ) : (
                        <p>None</p>
                      )}
                    </div>
                    <div></div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid conatiner className="created-date-box">
            <Grid item className='created-date'>
               Created {<CustomDateFormatter date={data?.date_created}/>}
               <Grid item className='updated-date'>
               Updated {differenceInDays(data?.updated_at) > 0 ? `${differenceInDays(data?.updated_at)} days ago` : 'Today'}
               </Grid>
            </Grid>
            </Grid> */}
          <div className="created-date-box">
            <div className="created-date">
              Created {<CustomDateFormatter date={data?.date_created} />}
              <div className="updated-date">
                Updated{" "}
                {differenceInDays(data?.updated_at) > 0
                  ? `${differenceInDays(data?.updated_at)} days ago`
                  : "Today"}{" "}
              </div>
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
}
