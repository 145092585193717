import { Button, Divider, Menu, MenuItem, MenuList } from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrow from "../../../../styles/svg/downArrow.svg";
import SearchBoard from "./SearchBoard";
import { useSelector } from "react-redux";
import { truncateText } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import {
  addAllBoard,
  addBoard,
  removeBoard,
} from "../../../../redux/SearchSlice";

function Boards() {
    const [activeBoardList,setactiveBoardList]= useState([])
    const [allChecked,setAllChecked] = useState(false)
    const [searchQuery, setSearchQuery] = useState("");
    const dispatch = useDispatch()
  const activeBoard = useSelector(
    (state) => state.activeBoard.activeBoardList
  );
  const checkedList = useSelector((state) => state.search.boardSearch);
  useEffect(() => {
    setactiveBoardList(activeBoard);
  }, [activeBoard]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
 const handleKeyDown = (event) => {
   // Prevent the default behavior for all key presses
if (event.key.length === 1) {
  event.stopPropagation();
}
 };

 const selectBoard = (event,id)=>{
  // if (!checkedList.includes(id)) {
    dispatch(addBoard([id]));
  // } else {
  //   dispatch(removeBoard(id));
  // }
  
 }
 const selectAll =(e)=>{
  // if (e.target.checked){
    dispatch(addAllBoard(activeBoard.map(el=>el.board_id)));
  // } else{
  //   dispatch(addAllBoard([]));
  // }
 }

  const filteredItems = searchQuery
    ? activeBoardList.filter((item) =>
        item.board_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : activeBoardList;

    console.log("checkedList", checkedList.length);
  return (
    <div className="mr-2">
      <Button
        variant="outlined"
        color="primary"
        id="epics-board-dropdown"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<img src={DownArrow} />}
        // className="white-button epics-btn"
        className="filter-btn white-button"
        sx={{
          height: "38px",
          "&:hover": {
            outline: "none",
            backgroundColor: "rgb(240 249 254)",
            border: "1px solid #1b3e61",
          },
        }}
      >
        {/* <img src={apicIcon} className="header-filter-icon" alt="not found" />{" "} */}
        <p className="header-action-button-text mr-4">
          {checkedList && (checkedList.length > 1
            ? "All Boards"
            : checkedList.length == 0 ? 'Select Board' : `${truncateText(
                activeBoard.find((el) => el.board_id == checkedList[0])
                  ?.board_name || '',
                26
              )}`)}
        </p>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="assign-board-owner-list"
      >
        {/* <MenuList onKeyDown={handleKeyDown}> */}
        <div onKeyDown={(e) => e.stopPropagation()}>
          <SearchBoard
            onSearch={(val) => setSearchQuery(val)}
            searchQuery={searchQuery}
          />
        </div>
        {!searchQuery && (
          <MenuItem onClick={selectAll}>
            {/* <label class="custom-checkbox">
              <input
                type="checkbox"
                id={`board-list-my-task-checkbox-all-board`}
              />
              <span class="checkmark"></span>
            </label> */}
            All Boards
          </MenuItem>
        )}
        {filteredItems.map((board, idx) => {
          return (
            <MenuItem
              key={`board-list-my-task-${board.board_id}`}
              className="custom-border"
              onClick={(event) => selectBoard(event, board.board_id)}
            >
              {/* <label class="custom-checkbox">
                <input
                  type="checkbox"
                  id={`board-list-my-task-checkbox-${board.board_id}`}
                  checked={checkedList.includes(board.board_id)}
                  onClick={(event) => {
                    console.log("Paragraph clicked");
                    event.stopPropagation();
                  }}
                />
                <span class="checkmark"></span> */}
              <p className="board-name-filter">
                {truncateText(board.board_name, 26)}
              </p>
              {/* </label> */}
              {/* <Divider className="m-0" /> */}
            </MenuItem>
          );
        })}
        {/* </MenuList> */}
      </Menu>
    </div>
  );
}

export default Boards;
