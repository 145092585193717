import React,{useState} from 'react'
import './epicbutton.scss'
import addEpicIcone from '../../../../../styles/svg/add-epic.svg'
import {
    Button,
    Divider
  } from '@mui/material';
import CreatEpicField from './CreateEpicField';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createEpic } from '../../../../../sevices/apiEpic';
import toast from 'react-hot-toast';
import { objectToFormData } from '../../../../../utils/helpers';

const CreateEpic = () => {
    const [createBtnOpen, setCreateBtnOpen] = useState(false);
    const [newEpic, setNewEpic] = useState("");
    const [isloading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const {id} = useParams()
    const { mutate: createEpicmutate } = useMutation({
        mutationFn: createEpic,
        onSuccess: (res) => {
          // queryClient.invalidateQueries({
          //   queryKey: [`boards`],
          // });
          // setComment('')
          queryClient.invalidateQueries({
            queryKey: [`epic`, id],
          });
          // setLoading(false);
          // console.log(res);
          //    reset();
          // setComment('')
          toast.success(res.data.message);
    
          // setOpenDialog(false);
    
          setLoading(false);
          setCreateBtnOpen(false);
        },
        onError: (error) => {
          console.error("Error assig board", error);
          toast.error(error.response.data.message);
          // setLoading(false);
          // setOpenDialog(false);
          // setLoading(false)
        },
      });

console.log("EPICNAME",newEpic)

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent default behavior of Enter key
          // Call your function here
          handleEnterKeyPress();
        setCreateBtnOpen(false)
        }
      };

    const handleEnterKeyPress = ()=>{
        console.log("EPICNAME",newEpic)
        // const epicFormData = objectToFormData({epic_name:newEpic})
        // console.log("epicFormData",epicFormData)
        // const data = {
        //     board_id: id,
        //     epic_name: newEpic
        // };
        createEpicmutate({
            board_id: id,
            data : {
                epic_name: newEpic
            }
        })
    }

  return (
    <>
        {createBtnOpen ? (
            <div onKeyDown={(e) => e.stopPropagation()}>
        <CreatEpicField
            handleKeyDown={handleKeyDown}
            setTitle={setNewEpic}
            minRows={0}
            creatEpic={true}
            setCreateBtnOpen={()=>setCreateBtnOpen(false)}/> </div>) : (
                <div className='create-epic-btn' onClick={()=>setCreateBtnOpen(true)}><Button 
         sx={{
            backgroundColor: "#1B3E61",
          "&:hover": {
            backgroundColor: "#1B3E61",
            border: "none",
          },
        }}
    variant="contained"> <img src={addEpicIcone}/> Create New</Button></div>
            )}
         
    </>
  )
}

export default CreateEpic