import { Button } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { getWorklogHistory } from '../../../../sevices/apiWorklog';

function Download() {
     const user = useSelector((state) => state.search.myTaskUser);
     const checkedList = useSelector((state) => state.search.boardSearch);
     const dateRange = useSelector((state) => state.search.dateRange);
     const epicSearch = useSelector((state) => state.search.epicSearch);

    const downloadCsv = ()=>{
         getWorklogHistory(user, checkedList, dateRange, epicSearch,true)
           .then(async (response) => {
              const text =  response.data
              console.log("CSV Text:", text);

              const blob = new Blob([text], { type: "text/csv" });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = "worklog.csv"; // Set the desired file name here
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
           })
           .catch((err) => {
             console.log(err);
           });
    }
  return (
    <Button
     variant="contained" className="see-worklogs" onClick={downloadCsv}>
      Download Worklogs
    </Button>
  );
}

export default Download