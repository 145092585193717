import React, { useEffect, useRef, useState } from 'react';
import Quill from 'quill';
// import 'quill/dist/quill.snow.css'; // Import Quill styles
{/* <script src="https://cdn.quilljs.com/1.3.6/quill.js"></script> */}
// import './textEditor.scss'
const TextEditor = ({description ,setTextContent}) => {
    const editorRef = useRef(null);
     
    useEffect(() => {
        const options = {
          debug: 'info',
          modules: {
            toolbar: true,
          },
          placeholder: 'Add Description...',
          theme: 'snow',
        };
    
        const quill = new Quill(editorRef.current, options);

        quill.on('text-change', () => {

        


          const typedText = quill.root.innerHTML;
    
          setTextContent(typedText);
         
      });

      
        quill.root.innerHTML = description
  
      
        return () => {
          quill.off('text-change');
          quill.off('selection-change');
          quill.disable();
        };
      }, [description]);
    return <div id="editor" ref={editorRef}></div>;
}

export default TextEditor