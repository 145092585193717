import moment from "moment";
import Api from "./axios";

let controller;

export const getWorklog = async(card_id)=>{
    return await Api.get(`/card_log/${card_id}/card`)
}

export const deleteWorklog = async(worklog_id)=>{
    return await Api.delete(`/card_log/${worklog_id}`)
}

export const createWorklog = async(data)=>{
    return await Api.post(`/card_log`,data,{
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
}

export const getWorklogDetail = async(id)=>{
    return await Api.get(`/card_log/${id}`)
}

export const updateWorklogDetail = async(data)=>{
    return await Api.put(`/card_log/${data.card_log_id}`,data.data,{
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
}

export const getWorklogHistory = async (
  user,
  checkedList,
  dateRange,
  epics,
  download = false
  ) => {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();
    const signal = controller.signal;
  const prepareBoardQuery = `${
    checkedList.length
      ? checkedList.length > 1
        ? checkedList
            .map((el, idx) => {
              // if (idx) {
              return `&boards=${el}`;
              // } else {
              //   return el;
              // }
            })
            .join("")
        : `&boards=${checkedList[0]}`
      : ""
  }`;
  console.log(dateRange);
  const prepareDateRangeQuery =
    dateRange &&
    `&start_date=${moment(dateRange.startDate).format(
      "YYYY-MM-DD"
    )}&end_date=${moment(dateRange.endDate).format("YYYY-MM-DD")}`;
  const prepareEpic = `${
    epics.length
      ? epics.length > 1
        ? epics
            .map((el, idx) => {
              // if (idx) {
              return `&epics=${el}`;
              // } else {
              //   return el;
              // }
            })
            .join("")
        : `&epics=${epics[0]}`
      : ""
  }`;
  const prepareUser = `${
    user.length
      ? user.length > 1
        ? user
            .map((el, idx) => {
              // if (idx) {
              return `&user_id=${el.user_id}`;
              // } else {
              //   return el;
              // }
            })
            .join("")
        : `&user_id=${user[0].user_id}`
      : ""
  }`;
  if (dateRange && !download) {
    return await Api.get(
      `/card_logs?${prepareUser}${prepareBoardQuery}${prepareDateRangeQuery}${prepareEpic}`,
      {
        signal: signal,
      }
    );
  } else if (download) {
    return await Api.get(
      `/card_logs?${prepareUser}${prepareBoardQuery}${
        prepareDateRangeQuery || ""
      }${prepareEpic}&download=true`,
      { 
      signal: signal,
        headers: {
          "Content-Type": "text/csv",
        },
      }
    );
  } else {
    return await Api.get(
      `/card_logs?${prepareUser}${prepareBoardQuery}${prepareEpic}`,
      {
        signal: signal,
      }
    );
  }
};