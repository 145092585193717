import moment from "moment";
import React from "react";

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const options = {
    month: "long",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };
  return date.toLocaleDateString("en-US", options);
}


// function formatOnlyDate(inputDate) {
//   // console.log("INPUT",inputDate)
//   const date = moment(inputDate).format("MM-DD-YYYY")
//   console.log("INPUT",date)
//   return date;
// }


function CustomDateFormatter({ date }) {
  const newDate = moment.utc(date);
  // console.log("INPUTlocal1",newDate)
  const localDate = newDate.local().toDate();
  // const localDate = moment(date)
  // console.log("INPUTlocal2",localDate)
  // if(dateOnly){
  //   var formattedDate = formatOnlyDate(localDate);
  // }else{
  //   var formattedDate = formatDate(localDate);
  // }
  
  const formattedDate = formatDate(localDate);
  return <span>{formattedDate}</span>;
}

export default CustomDateFormatter;
