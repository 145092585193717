import KanbanBoard from "./KanbanBoard";
import "./kanban.scss";

function Kanban({ workflowSteps, cards, id, boardName }) {
  const style = {
    // padding: "30px",
    paddingTop: "65px",
  };

  return (
    <div style={style}>
      <KanbanBoard
        boardName={boardName}
        workflowSteps={workflowSteps}
        cards={cards}
        id={id}
      />
    </div>
  );
}

export default Kanban;
