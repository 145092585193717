export default function GetHeadShotName(userName) {
    // Split the username by space
    const parts = userName.split(' ');

    // Check if there are at least two parts (first name and last name)
    if (parts.length >= 2) {
        const firstInitial = parts[0].charAt(0).toLowerCase();
        const secondInitial = parts[1].charAt(0).toLowerCase();
        return firstInitial + secondInitial;
    }

    // If there is no space, return the first character of the username
    return userName.charAt(0).toLowerCase();
}