import * as yup from "yup";
export const schema = yup
  .object({
    work_performed: yup.string().required("Work  is required"),
    hours_spent: yup
      .string()
      .required("Work Hours is required"),
      date_time: yup.date().required("Time is required"),
  })
  .required();
