import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField, Grid } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { localStorageInstance } from "../../../../utils/localStorage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAttachments } from "../../../../sevices/apiBoards";
import toast from "react-hot-toast";
import Loader from "../../../../components/Loader";
import { objectToFormData } from "../../../../utils/helpers";
import InputFileUpload from "../../../../components/FileUploadButton";
import { useSelector } from "react-redux";

function Form() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const userData = useSelector((state) => state.users.logInUser);
  const [loading, setLoading] = useState(false);
  // console.log(userData);

  // React Hook Form
  const { register, handleSubmit, watch, reset, getValues, formState } =
    useForm({
      defaultValues: {
        board_id: id,
        user_id: userData.user_id,
      },
      // resolver: yupResolver(),
    });
  const { errors } = formState;
  // console.log("formState", getValues());

  const { mutate: createAttachmentMutate } = useMutation({
    mutationFn: createAttachments,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`attachments-${id}`],
      });
      setLoading(false);
      reset();
      toast.success("Attachment added successfully");
      //    onClose();
    },
    onError: (error) => {
      console.error("Error Add Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
    },
  });

  const submit = (data) => {
    /**
     * Add Attachments
     */
    const formData = objectToFormData({ ...data, file: data.file[0] });
    setLoading(true);
    // console.log(data);
    createAttachmentMutate(formData);
  };
  return (
    <>
      <div className="attachments-form-container">
        <Box
          component="form"
          onSubmit={handleSubmit(submit)}
          noValidate
          className="d-flex"
          sx={{
            bgcolor: "rgba(242, 244, 246, 1)",
            // marginTop: 4,
            padding: "16px",
            justifyContent: "space-between",
            border: "1px solid rgba(145, 158, 171, 0.12)",
            borderRadius: "8px",
            // boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
          }}
        >
          <Grid
            container
            item
            xs={12}
            className="remove-container-width"
            justifyContent={"center"}
            alignItems={"center"}
            columnSpacing={2}
          >
            <Grid item xs={5} md={5} className="pl-0" sx={{ paddingLeft: "0" }}>
              <TextField
                label={""}
                placeholder="Name"
                required
                id="name"
                name="name"
                error={errors?.name ? true : false}
                helperText={errors?.name && errors.name.message}
                {...register("name")}
                sx={{
                  bgcolor: "white",
                  borderRadius: "4px",
                  border: "1px solid #919EAB40",
                }}
              />
            </Grid>
            <Grid item xs={5} md={5}>
              <InputFileUpload
                watch={watch}
                register={register}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <Button
                type="submit"
                variant="contained"
                className=""
                sx={{
                  bgcolor: "rgba(27, 62, 97, 1)",
                  minWidth: "1px",
                  width: "100%",
                  height: "52px",
                  textTransform: "capitalize",
                }}
              >
                {loading ? <Loader /> : "Add"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default Form;
