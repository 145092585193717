import Api from "./axios";

export const getWorkflows = async () => {
  return await Api.get(`/workflows`);
};

export const getWorkflowById = async (workflow_id, board_id) => {
  return await Api.get(
    `/workflow_steps/${workflow_id}/workflow_steps`
  );
};
