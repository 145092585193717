import { Box, Grid } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import NavigationBoards from "./components/Boards";
import "./dashboard.scss";

export default function Dashboard() {
  return (
    <Box>
      <Header />
      <Box className="wrapper_dashboard">
        <Box className="wrapper_sidebar">
          <NavigationBoards />
        </Box>
        <Box className="wrapper_main_container">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="main-container">
                <Outlet />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
