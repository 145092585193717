import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expanded: "panel1",
};

const boardList = createSlice({
  name: "boardList",
  initialState,
  reducers: {
    changeExpand: {
      prepare(expanded) {
        return {
          payload: { expanded },
        };
      },
      reducer(state, action) {
        state.expanded = action.payload.expanded;
      },
    },
  },
});

export const { changeExpand } = boardList.actions;

export default boardList.reducer;
