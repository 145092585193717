import React from "react";
import Loader from "../../../components/Loader";
import { useBoards } from "../../../utils/reactQuery/boards";
import BoardList from "./BoardList";
import { useDispatch } from "react-redux";
import { setActiveBoards } from "../../../redux/ActiveBoardSlice";

function Navigation() {
  const dispatch=useDispatch();
  const { isLoading, boards, isError } = useBoards();
  // console.log("left boards",isLoading, boards);
  if(boards){
    dispatch(
      setActiveBoards(
        boards?.data?.data?.responseData
          .filter((el) => !el.is_archive)
          .sort(function (a, b) {
            return a.board_name.localeCompare(b.board_name);
          }) || []
      )
    );
  }
  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && <BoardList boards={[]} />}
      {!isLoading && !isError && (
        <BoardList boards={boards.data.data.responseData} />
      )}
    </>
  );
}

export default Navigation;
