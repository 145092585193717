import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, MenuItem, Stack } from "@mui/material";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import Field from "../../../../../components/field/index";
import { useUsers } from "../../../../../utils/reactQuery/users";
import { assignUser } from "../../../../../sevices/apiBoards";
import { useParams } from "react-router-dom";
import Loader from "../../../../../components/Loader";

function Form({ onClose }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { isLoading: isUserLoading, users, isError: isUserError } = useUsers();
  const [userOption, setUserOption] = useState([]);
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (!isUserLoading && !isUserError) {
      let filteredArray = users.data.data.responseData
        .filter((el) => el.active)
        .sort(function (a, b) {
          return a.first_name.localeCompare(b.first_name);
        });
      setUserOption(
        filteredArray.map((el) => {
          return {
            label: `${el.first_name} ${el.last_name} (${el.email})`,
            value: el.user_id,
          };
        })
      );
    }
  }, [users, isUserLoading]);

  const roles = [
    {
      label: "User",
      value: "User",
    },
    {
      label: "Board Admin",
      value: "Board Admin",
    },
  ];

  // React Hook Form
  const { register, handleSubmit, reset, getValues, formState } = useForm({
    defaultValues: {
      board_id: id,
    },
    // resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const { mutate: assignUserMutate } = useMutation({
    mutationFn: assignUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`boardUsers`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`boardName`, id],
      });
       queryClient.invalidateQueries({
         queryKey: [`boards`],
       });
      setLoading(false);
      //    reset();
      // console.log(res);
      toast.success(res.data.message);
      //    onClose();
      onClose();
    },
    onError: (error) => {
      console.log(error);
      //   console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
      //   handleClose();
    },
  });

  const submit = (data) => {
    /**
     * Create Board
     */
    setApiError("");
    setLoading(true);
    // console.log(data);
    assignUserMutate(data);
  };
  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(submit)}
        noValidate
        sx={{
          // marginTop: 4,
          padding: "14px",
          // borderRadius: "16px",
          // boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
        }}
      >
        <Stack spacing={3}>
          {apiError && <Alert severity="error">{apiError}</Alert>}
          <Field
            label={"User"}
            required
            id="user_id"
            name="user_id"
            error={errors?.user_id ? true : false}
            helperText={errors?.user_id && errors.user_id.message}
            select
            {...register("user_id")}
          >
            {userOption.length ? (
              userOption.map((option) => (
                <MenuItem
                  key={`user-option-${option.value}`}
                  id={`user-option-id-${option.value}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))
            ) : (
              <div className="loader-center">
                <Loader color={"black"} />
              </div>
            )}
          </Field>
          <Field
            label={"Role"}
            required
            id="user_role"
            name="user_role"
            error={errors?.user_role ? true : false}
            helperText={errors?.user_role && errors.user_role.message}
            select
            {...register("user_role")}
          >
            {roles.map((option) => (
              <MenuItem
                key={`workflow-option-${option.value}`}
                id={`workflow-option-id-${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Field>

          <Box
            sx={{
              alignSelf: "end",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#1B3E61",
                mr: "10px",
                fontSize: "16px",
                padding: "10px 16px",
                fontWeight: "600",
                textTransform: "capitalize",
                minWidth: "130px",
              }}
              disabled={loading}
            >
              Create
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#1B3E61",
                border: "1px solid #1B3E61",
                textTransform: "capitalize",
                fontSize: "16px",
                padding: "10px 16px",
                fontWeight: "600",
                opacity: "0.6",
                minWidth: "130px",
              }}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default Form;
