import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import Form from "./Form";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DialogCreateBoard({ handleClose, open }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        open={open}
        fullScreen={fullScreen}
        sx={{
          height: "90vh",
          "& .MuiPaper-root": {
            borderRadius: "12px",
            maxWidth: "470px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "rgba(242, 246, 254, 1)" }}
          id="responsive-dialog-title"
        >
          Create Board
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="dialog-content-form">
          <Form onClose={handleClose} />
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
