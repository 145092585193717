import * as React from 'react';
import { useState,useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import CloseRounded from '@mui/icons-material/CloseRounded';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useCard } from '../../../../../../../../utils/reactQuery/cards';


export default function SingleSelectDropdown({epicsName,cardId,handleEpicBlur}) {
  const [selectEpic, setSelectEpic] = useState('');
  const [openList, setOpenList] = useState(true)
        console.log("selectEpic",selectEpic)

        const {
            isLoading: isCardLoading,
            card,
            isError: isCardError,
          } = useCard(cardId);
          const data = card?.data?.data?.responseData;
        //   console.log("value", )

  useEffect(() => {
    // Update the state if defaultValue changes
    if (data?.epic?.epic_id) {
        setSelectEpic(data.epic.epic_id);
      }
  }, [data?.epic?.epic_name ]);


  const handleClear = () => {
    
    setSelectEpic(0);
    handleEpicBlur(0);
    
  };



  const handleChange = (event) => {
    setSelectEpic(event.target.value);
    handleEpicBlur(event.target.value)
    
  };
  // console.log("INEPIC2")
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
      <Select
        
        labelId="demo-select-small-label"
        id="demo-select-small"
        // defaultValue={data?.epic?.epic_name}
        value={selectEpic}
        // label="selectEpic"
        onChange={handleChange}
        onBlur={(event)=>handleEpicBlur(event.target.value)}
        // MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
        sx={{
            width: '180px', // Set width to 200px
            height: '32px', // Set height to 40px
            marginRight: '2px', // Set margin-right to 10px 
          }}
          autoFocus
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '160px', // Set max height for the dropdown list
              },
            },
          }}   


          endAdornment={
            selectEpic ? (
              <IconButton
                size="small"
                onClick={handleClear}
                onMouseDown={(event) => {
                 
                  event.preventDefault(); // Prevents the menu from closing
                  event.stopPropagation(); // Prevents the onClick on the menu item from firing
                }}
                sx={{
                  marginRight:'15px'
                }}
              >
                <CloseRounded />
              </IconButton>
            ) : null
          }


      >
        
        <MenuItem value="0">
          <em>None</em>
        </MenuItem >
        {
            epicsName?.map((item)=><MenuItem value={item.epic_id}>{item.epic_name}</MenuItem>)
        }
        
      </Select>

    </FormControl>
  );
}
