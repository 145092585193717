import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Form from "./Form";
import List from "./List";

export default function AnchorTemporaryDrawer({ toggleDrawer, state }) {
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 520 }}
      role="presentation"
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "700",
          bgcolor: "rgba(242, 246, 254, 1)",
        }}
        id="customized-dialog-title"
        className="blue-text-color attachment-header-text"
      >
        Attachments
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={toggleDrawer(anchor, false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Form />
        <Box sx={{ height: "24px" }}></Box>
        <List />
      </DialogContent>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
