import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { truncateText } from "../utils/helpers";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileUpload({ register, getValues, watch }) {
  // Watch all fields and trigger onChange event
  const watchedFields = watch();

  React.useEffect(() => {
    // handleChange(watchedFields);
    // console.log(watchedFields);
  }, [watchedFields]);
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      className="white-button file-upload-button"
      sx={{
        width: "100%",
        height: "56px",
        boxShadow: "none",
        textTransform: "capitalize",
      }}
      startIcon={watchedFields?.file?.length ? null : <CloudUploadIcon />}
    >
      {watchedFields?.file?.length
        ? truncateText(watchedFields.file[0].name, 12)
        : "Upload file"}
      <VisuallyHiddenInput type="file" {...register("file")} />
    </Button>
  );
}
