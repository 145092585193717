import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import DownArrow from "../../../../styles/svg/downArrow.svg";
import { useUserBoardEpic } from "../../../../utils/reactQuery/epics";
import { useSelector } from "react-redux";
import { addAllEpic, addEpic } from "../../../../redux/SearchSlice";
import { useDispatch } from "react-redux";

function Epics() {
  const dispatch =useDispatch()
  const myTaskUser = useSelector((state) => state.search.myTaskUser);
    const checkedList = useSelector((state) => state.search.epicSearch);
     const board_id = useSelector((state) => state.search.boardSearch)[0];
  const { epic } = useUserBoardEpic(board_id);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
 const selectEpic = (event, id) => {
   dispatch(addEpic(id));
 };
  const selectAll = (e) => {
    if (e.target.checked) {
      dispatch(
        addAllEpic(epic?.data.data.responseData.map((el) => el.epic_id))
      );
    } else {
      dispatch(addAllEpic([]));
    }
  };
  return (
    <div className="mr-2">
      <Button
        variant="outlined"
        color="primary"
        id="epics-board-dropdown"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<img src={DownArrow} />}
        // className="white-button epics-btn"
        className="filter-btn white-button"
        sx={{
          height: "38px",
          "&:hover": {
            outline: "none",
            backgroundColor: "rgb(240 249 254)",
            border: "1px solid #1b3e61",
          },
        }}
      >
        {/* <img src={apicIcon} className="header-filter-icon" alt="not found" />{" "} */}
        <p className="header-action-button-text mr-4">Epic</p>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="assign-board-owner-list"
      >
        {epic && epic.data.data.responseData.length > 0 && (
          <MenuItem>
            <label class="custom-checkbox">
              <input
                type="checkbox"
                id={`board-list-my-task-checkbox-all-board`}
                onChange={selectAll}
              />
              <span class="checkmark"></span>
              Select All Epics
            </label>
          </MenuItem>
        )}
        {epic &&
          epic.data.data.responseData.length  > 0 &&
          epic?.data.data.responseData.map((el, idx) => {
            return (
              <MenuItem
                key={`my-task-epic-${idx}`}
                className="custom-border"
                onClick={(event) => selectEpic(event, el.epic_id)}
              >
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`board-list-my-task-checkbox-all-board`}
                    checked={checkedList.includes(el.epic_id)}
                    onClick={(event) => {
                      console.log("Paragraph clicked");
                      event.stopPropagation();
                    }}
                  />
                  <span class="checkmark"></span>
                  <p className="board-name-filter">{el.epic_name}</p>
                  {/* <p>
                    {el.epic_id}
                  </p> */}
                </label>
              </MenuItem>
            );
          })}
        {epic && !epic.data.data.responseData.length && (
          <MenuItem>No Epic found</MenuItem>
        )}
      </Menu>
    </div>
  );
}
export default Epics;
