import React, { useEffect, useState } from 'react'
import { useWorkflowById } from '../../../../utils/reactQuery/workflows'
import Loader from '../../../../components/Loader';
import Kanban from './Kanban';
import { getMyCards } from '../../../../sevices/apiCards';
import { useSelector } from 'react-redux';

function Workflow() {
    const {data} =  useWorkflowById(1,528);
    const [cards,setCards] = useState([])
    const [loading,setLoading] = useState(true);
    const user = useSelector(
      (state) => state.search.myTaskUser
    );
    const checkedList = useSelector((state) => state.search.boardSearch);
    const dateRange = useSelector((state) => state.search.dateRange);
    const epicSearch = useSelector((state) => state.search.epicSearch);
    ;

    useEffect(() => {
      setLoading(true);
      getMyCards(user, checkedList, dateRange, epicSearch)
        .then((res) => {
          setCards(res.data.data.responseData);
          setLoading(false);
        })
        .catch((err) => {
           if (err.code != "ERR_CANCELED") setLoading(false);
           setCards([])
        })
   
    }, [user, checkedList, dateRange, epicSearch]);

   
  return (
    <>
      {data && !loading ? (
        <Kanban cards={cards} workflowSteps ={data.data.data.responseData}/>
      ) : (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
    </>
  );
}

export default Workflow