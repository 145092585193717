import React,{ useRef } from 'react'
import { styled } from "@mui/material/styles";
import Avatar from "../Avatar";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { objectToFormData } from '../../utils/helpers';
import toast from 'react-hot-toast';
import { updateImage } from '../../sevices/apiUploadImage';
import { localStorageInstance } from '../../utils/localStorage';
import pencilIcon from '../../styles/svg/edit-title.svg'
// import pencilIcon from '@mui/icons-material/Edit';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import './uploadImageAvtar.scss'
import { useParams } from 'react-router-dom';
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function UploadImage({user,userName,setAnchorEl}) {
    const queryClient = useQueryClient();
    const {id} = useParams();
    const userId = localStorageInstance.getItem("userId");

    const { mutate: updateImageMutate } = useMutation({
        mutationFn: updateImage,
        onSuccess: (res) => {
          // console.log("Worklog Updated successfully");
          queryClient.invalidateQueries({
            queryKey: ['me'],
          });  
          queryClient.invalidateQueries({
            queryKey: ['cards',id],
          });  
          queryClient.invalidateQueries({
            queryKey: ['boards']
          });  
          queryClient.invalidateQueries({
            queryKey: ['boardUsers',id]
          });  
        //   setDisable(false)
        //   // console.log(res)
        //   setLoading(false);
          // reset();
          toast.success("successfully updated");
          setAnchorEl(null)
          // onClose();
          // refetchWorklogDetail();
        },
        onError: (error) => {
        //   setLoading(false);
        //   setDisable(false)
        },
      });

      const upload = (e)=>{
        e.stopPropagation();
        console.log("file",e.target.files[0])
        const files = objectToFormData({
            
            file: e.target.files[0],
          }); 
          console.log("file",files)
    //     const formData = new FormData();
    //   formData.append('file', e.target.files[0]);
        updateImageMutate({
            user_id:userId,
            user_image: e.target.files[0]
        })
       
    }


  return (
    <div className="avatar-container">
      <label>
        <VisuallyHiddenInput type="file" onChange={upload} />
        <Avatar
          user_image={user?.user_image}
          title={`${user?.first_name} ${user?.last_name}`}
          className="user-avatar"
          style={{
            bgcolor: user?.profile_color?.background,
            color: user?.profile_color?.text,
          }}
        >
          {userName}
        </Avatar>
        <div className="icon-overlay">
            {/* <img src={pencilIcon} alt="Edit" /> */}
            <ModeEditOutlinedIcon  sx={{color: "white", fontSize: "16px"}}/>
        </div>
      </label>
      </div>
  );
  
}

export default UploadImage