import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import './datePicker.scss'

const DatePickerSmall = ({date,handleDueDateKeyDown,handleDuedateBlur}) => {

  return (
    <>
    <DatePicker      
     isClearable
    selected={date} 
    onChange={(newdate) => {
      console.log("date..... ",moment(newdate).format('MM-DD-YYYY'))
      handleDueDateKeyDown(newdate);
    }} 
    // onBlur={()=>{handleDuedateBlur(false)
    // //   console.log("ONBLUR")
    // }}
    dateFormat="MM/dd/yyyy" className="custom-datePicker" 
    autoFocus />
   </>
  );
};

export default DatePickerSmall;







// import React, { useRef } from 'react';

// const DatePickerSmall = ({date,handleDueDateKeyDown,handleDuedateBlur}) => {
//   const buttonRef = useRef(null);

//   const handleDivBlur = (event) => {
//     // Check if the button is the active element to prevent blur logic
//     if (buttonRef.current && buttonRef.current.contains(document.activeElement)) {
//       return;
//     }
//     console.log("Div Blur Event Triggered");
//     // Place your blur logic here
//   };

//   const handleButtonClick = (event) => {
//     event.stopPropagation();
//     console.log("Button Clicked");
//     // Place your button click logic here
//   };

//   return (
//     <div
//       tabIndex="0" // Make the div focusable to receive blur events
//       onBlur={handleDivBlur}
//       style={{ padding: '20px', border: '1px solid black' }}
//     >
//       <p>Click the button to see the interaction</p>
//       <button
//         ref={buttonRef}
//         onClick={handleButtonClick}
//       >
//         Click Me
//       </button>
//     </div>
//   );
// };

// export default DatePickerSmall;








// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import moment from 'moment';
// import "react-datepicker/dist/react-datepicker.css";
// import './datePicker.scss'

// const DatePickerSmall = ({date,setDate,handleDueDateKeyDown,isValid,setDueDate}) => {
//   const [focus, setFocuse] = useState(false)
//   const handleBlur =({target:{value}})=>{

    
//     setFocuse(false)
//     const date = new Date(value);
//     if (date){
//       console.log("date: %s", date);
//     } else {
//       console.log("value: %s", date);
//     }
//   }

//   const handleFocus =()=>{
//     setFocuse(!focus)
//   }
//   return (
//     <DatePicker 
//     disabled={isValid}     
//     // open={isValid}
//      isClearable
//     selected={date} 
//     onChange={(newdate) => {
//       console.log("date..... ",moment(newdate).format('MM-DD-YYYY'))
//       setFocuse(true)
//       handleDueDateKeyDown(newdate);
//       // setDueDate(false)
//     }}
//     onFocus={handleFocus} 
//      onBlur={handleBlur}
//     dateFormat="MM/dd/yyyy" className="custom-datePicker"  placeholderText="None"/>
//   );
// };

// export default DatePickerSmall;
 
 
 
 
 
